import styled from 'styled-components'

export const StyledUbisoft = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 80%;

    @media (max-width: 768px) {
      width: 95%;
    }
  }
  .section-2 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 70%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left,
    .right {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .section-3 {
    width: 80%;

    @media (max-width: 768px) {
      width: 95%;
    }
  }
  .section-4 {
    width: 52%;

    @media (max-width: 768px) {
      width: 63%;
    }
  }
  .section-5 {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 65%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left,
    .right {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`

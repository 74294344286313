// Dependencies
import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { HitMe } from '../../Components/HitMe/HitMe'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'
import { gsap } from 'gsap'

// Styles
import {
  StyledAbout,
  Brand,
  AboutTitle,
  CvTitle,
  CvSubTitle,
  CvText,
} from './About.styled'

// Images
import Img1 from '../../Assets/Img/About/img-1.png'
import Img2 from '../../Assets/Img/About/img-2.png'
import Img3 from '../../Assets/Img/About/img-3.png'
import Img4 from '../../Assets/Img/About/img-4.png'
import Img5 from '../../Assets/Img/About/img-5.png'
import Img6 from '../../Assets/Img/About/img-6.png'
import Img7 from '../../Assets/Img/About/img-7.png'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniYSL.png'
import Footer2 from '../../Assets/Img/Footer/MiniBIOTHERM.png'
import Footer3 from '../../Assets/Img/Footer/MiniLIBRE.png'

export const About = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()
  const profilTurbulenceMob = useRef()
  const profilPictureMob = useRef()
  const textMob = useRef()
  const profilTurbulence = useRef()
  const profilPicture = useRef()
  const titleOne = useRef()
  const titleTwo = useRef()
  const socialLinks = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer
  var tlMob = gsap.timeline()
  var tl = gsap.timeline()

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    if (size.width > 1024) {
      tlMob
        .from(profilPictureMob.current, {
          duration: 1,
          opacity: 0,
          y: 50,
          stagger: 0.6,
        })
        .to(
          profilTurbulenceMob.current,
          { duration: 1, attr: { baseFrequency: '0 0' }, stagger: 0.2 },
          '<'
        )
        .from(textMob.current, { duration: 1, opacity: 0, y: 20 }, 1)

      tl.from(profilPicture.current, {
        duration: 1,
        opacity: 0,
        y: 50,
        stagger: 0.6,
      })
        .to(
          profilTurbulence.current,
          { duration: 1, attr: { baseFrequency: '0 0' }, stagger: 0.2 },
          '<'
        )
        .from(titleOne.current, { duration: 1, opacity: 0, y: 20 }, 1)
        .from(titleTwo.current, { duration: 1, opacity: 0, y: 20 }, '<')
        .from(socialLinks.current, { duration: 1, opacity: 0, y: 20 }, 1.5)
    }
  }, [tlMob, tl])
  useEffect(() => {
    if (size.width > 1024) {
      tlMob.play()
      tl.play()
    }
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <>
      <svg>
        <filter id="profilFilterMob" x="0%" y="0%" width="100%" height="100%">
          <feTurbulence
            baseFrequency="0.01 0.03"
            result="NOISE"
            numOctaves="1"
            id="turbulence"
            ref={profilTurbulenceMob}
          />
          <feDisplacementMap
            in="SourceGraphic"
            in2="NOISE"
            scale="20"
          ></feDisplacementMap>
        </filter>
      </svg>
      <svg>
        <filter id="profilFilter" x="0%" y="0%" width="100%" height="100%">
          <feTurbulence
            baseFrequency="0.01 0.03"
            result="NOISE"
            numOctaves="1"
            id="turbulence"
            ref={profilTurbulence}
          />
          <feDisplacementMap
            in="SourceGraphic"
            in2="NOISE"
            scale="20"
          ></feDisplacementMap>
        </filter>
      </svg>
      <div className="Page" ref={page}>
        <Backgroud />
        <Navigation />
        <StyledAbout ref={scrollContainer} id="dismissNavbar">
          <div className="header">
            <div className="mob">
              <div className="img">
                <img src={Img1} ref={profilPictureMob} alt="" />
              </div>
              <div className="text" ref={textMob}>
                <h1>Alice</h1>
                <h1>Jestin</h1>
              </div>
            </div>
            <div className="top">
              <h1 ref={titleOne}>Alice</h1>
              <div className="img">
                <img src={Img1} ref={profilPicture} alt="" />
              </div>
              <h1 ref={titleTwo}>Jestin</h1>
            </div>
            <div className="bot" ref={socialLinks}>
              <a
                href="https://www.instagram.com/alicejstnart/"
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram
              </a>
              <p>-</p>
              <a
                href="https://dribbble.com/AliceJstn"
                target="_blank"
                rel="noopener noreferrer"
              >
                dribble
              </a>
              <p>-</p>
              <a
                href="https://www.behance.net/jestinalicaf0f"
                target="_blank"
                rel="noopener noreferrer"
              >
                behance
              </a>
              <p>-</p>
              <a
                href="https://www.linkedin.com/in/alice-jestin-9b261112b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                linkedin
              </a>
            </div>
          </div>
          <div className="section-1">
            <div className="left">
              <h2>Art Direction</h2>
              <h2>Illustration</h2>
              <h2>Mobile</h2>
              <h2>& Web design</h2>
            </div>
            <div className="right">
              <p>
                Bonjour, i&apos;m Alice jestin, passionate art director &amp;
                graphic designer based in Paris, graduated from IMAC engineering
                school in 2017, and currently Freelancer.
                <br />
                Hit me up for future projects!
              </p>
              <a href="./CV-AliceJestin.pdf" target="_blank">
                Curriculum Vitae _PDF
              </a>
            </div>
          </div>
          <div className="section-2">
            <AboutTitle>I worked for brands like</AboutTitle>
            <div className="list">
              <div className="row">
                <Brand width={86} margin={0}>
                  <div className="img">
                    <img src={Img2} alt="" />
                  </div>
                  <h2>Yves Saint Laurent</h2>
                </Brand>
              </div>
              <div className="row">
                <Brand width={130} margin={60}>
                  <div className="img">
                    <img src={Img3} alt="" />
                  </div>
                  <h2>Biotherm</h2>
                </Brand>
                <Brand width={100} margin={0}>
                  <div className="img">
                    <img src={Img4} alt="" />
                  </div>
                  <h2>Prada</h2>
                </Brand>
              </div>
              <div className="row">
                <Brand width={78} margin={60}>
                  <div className="img">
                    <img src={Img5} alt="" />
                  </div>
                  <h2>Ubisoft</h2>
                </Brand>
                <Brand width={132} margin={0}>
                  <div className="img">
                    <img src={Img6} alt="" />
                  </div>
                  <h2>Bourjois</h2>
                </Brand>
              </div>
              <div className="row">
                <Brand width={132} margin={0}>
                  <div className="img">
                    <img src={Img7} alt="" />
                  </div>
                  <h2>Rogé Cavaillès</h2>
                </Brand>
              </div>
            </div>
            <div className="text">
              <h3>
                But also Franprix, Cartier, Clarins, Fenty, Garnier, Vichy, and
                other clients in the banking, real estate, education and food
                industries.
              </h3>
            </div>
          </div>
          <AboutTitle>my curriculum vitae</AboutTitle>
          <div className="section-3">
            <div className="item">
              <div className="bloc">
                <CvTitle>Professional experience</CvTitle>
                <CvSubTitle>FREELANCER (OCT 2020 - TODAY)</CvSubTitle>
                <CvText>
                  Work for brands like : Cartier, YSL, Prada, Ubisoft, Bourjois,
                  Rogé Cavaillès, Franprix, Clarins, &amps; other clients in the
                  banking, real estate, education and food industries.
                  <br />
                  Ul Design, Print Layout, Digital preprod, digital activation
                  on luxury brands, social media, presentation design, graphic
                  design, illustration ...
                </CvText>
                <CvSubTitle>
                  AD JUNIOR (FEB 2018 - OCT 2020) AT PUBLICIS LUXE
                  (SAPIENTRAZORFISH X PUBLICIS133) PARIS, FRANCE
                </CvSubTitle>
                <CvText>
                  Digital activations on luxury, skincare, beauty brands
                  (L&apos;Oréal Group) for China and Europe. Webdesign (UI
                  design, product page, digital experiences, newsletters),
                  digital videos (storyboarding, shooting intentions), social
                  media...
                </CvText>
                <CvSubTitle>
                  UI / UX DESIGNER - GRAPHIC DESIGNER (2017) AT COSAVOSTRA
                  PARIS, FRANCE
                </CvSubTitle>
                <CvText>
                  Digital agency and Startup Studio, Design an website
                  conception. 6 months intership. Models and visual identity. UI
                  and UX design, Wordpress integration and newsletters.
                </CvText>
                <CvSubTitle>
                  WEBDESIGNER - FRONT-END DEVELOPPER (2016)?AT AGENCE HORIZON
                  QUIMPER, FRANCE
                </CvSubTitle>
                <CvText>
                  Agency specialized in Open-Source solutions. 3 months
                  intership in Webdesign, development and integration. Website
                  redesign, website creations. CMS development (Wordpress,
                  Magento).
                </CvText>
              </div>
              <div className="bloc">
                <CvTitle>Education</CvTitle>
                <CvSubTitle>
                  CACATOES ACADEMY - WORKSHOP FIGMA (2022)
                </CvSubTitle>
                <CvText>
                  Getting started with the interface, preparing files for
                  design, Creating and managing styles, Groups and frames,
                  Understanding autolayout, Managing constraints, Creating
                  variant components, Working with real content, Managing
                  libraries, Collaboration in Figma, Essential plugins, Sharing
                  a prototype, Essential keyboard shortcuts.
                </CvText>
                <CvSubTitle>GOBELIN, L’ECOLE DE L’IMAGE (2019)</CvSubTitle>
                <CvText>
                  Formation professionnelle en UX Design 2019. UX Design -
                  Methodologie et applications. Fondamentaux de
                  l&apos;ergonomie. UX Design - Ateliers
                </CvText>
                <CvSubTitle>DIGITAL ACTIVE BY GOOGLE (2016)</CvSubTitle>
                <CvText>
                  Certified digital marketing training by Google. Development of
                  digitals skills such as SEO, emailing, e-commerce...
                </CvText>
                <CvSubTitle>
                  ENGINEERING DEGREE (2014 - 2017) AT IMAC PARIS, FRANCE
                </CvSubTitle>
                <CvText>
                  Associating ceative spirit with scientifics skills, IMAC
                  trains professionals in the area of the web (development,
                  integration, UI/UX design, e-marketing...), multimédia (3D,
                  special effects, VR...), audiovisual (interactive device,
                  soundesign, scenography...), communication (artistic
                  direction...)
                </CvText>
                <CvSubTitle>
                  CLASSES PRÉPARATOIRES AUX GRANDES ÉCOLES SCIENCES (2012 -
                  2014) À CPGE BRIZEUX QUIMPER, FRANCE
                </CvSubTitle>
                <CvText>
                  Preparation for national competitive entrance exams to leading
                  French &quot;grandes écoles&quot;, specialising in mathematics
                  and physics.
                </CvText>
              </div>
            </div>
            <div className="item">
              <div className="bloc">
                <CvTitle>Contact</CvTitle>
                <a href="mailto:jestin.alice@gmail.com">
                  jestin.alice@gmail.com
                </a>
                <a href="tel:+33603999261">06 03 99 92 61</a>
                <Link to="/">www.alicejestin.com</Link>
              </div>
              <div className="bloc">
                <CvTitle>Skills</CvTitle>
                <CvSubTitle>INTERFACE DESIGN</CvSubTitle>
                <CvText>
                  Website models, Design System, prototype, graphic charter,
                  Logos, benchmarks, wireframes...
                  <br />
                  Software like Figma, Sketch, Adobe XD, Photoshop, Illustrator,
                  Invision, Marvelapp
                </CvText>
                <CvSubTitle>PRINT</CvSubTitle>
                <CvText>
                  Graphic charter, illustrations, brochures, flyers, posters,
                  goodies...
                  <br />
                  Photoshop, Indesign, Illustrator
                </CvText>
                <CvSubTitle>ILLUSTRATION</CvSubTitle>
                <CvText>Procreate, Photoshop, Illustrator</CvText>
                <CvSubTitle>OTHER ABILITES</CvSubTitle>
                <CvText>
                  Video (After Effects, Premiere Pro, notion on Da Vinci
                  Resolve)
                  <br />
                  Photography (Lightroom, Photoshop, Luminar)
                  <br />
                  Front-End development (HTML, CSS, Wordpress, Zeplin, Sublime
                  text, MailChimp)
                </CvText>
              </div>
              <div className="bloc">
                <CvTitle>Languages</CvTitle>
                <CvSubTitle>FRENCH (NATIVE)</CvSubTitle>
                <CvSubTitle>ENGLISH (TOEIC 845)</CvSubTitle>
                <CvSubTitle>ISPANISH (NOTIONS)</CvSubTitle>
              </div>
              <div className="bloc">
                <CvTitle>Interest</CvTitle>
                <CvText>
                  Illustration, Painting ( Watercolor, oil, gouache)
                  <br />
                  Photography, Filmmaking
                  <br />
                  Travel, Cooking
                </CvText>
              </div>
            </div>
          </div>
          <HitMe marginTop={200} />
          <Footer
            lock1={true}
            img1={Footer1}
            link1="/YvesSaintLaurentWebsite"
            title1="Yves Saint Laurent"
            text1="website"
            lock2={false}
            img2={Footer2}
            link2="/BiothermLifePlankton"
            title2="Biotherm"
            text2="life plankton™"
            lock3={false}
            img3={Footer3}
            link3="/YvesSaintLaurentLibreRange"
            title3="Yves Saint Laurent"
            text3="libre range"
          />
        </StyledAbout>
      </div>
    </>
  )
}

import styled from 'styled-components'

export const StyledLifePlankton = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .container-projectLife {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .section-1 {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 200px;
    max-width: 1440px;

    @media (max-width: 768px) {
      width: 80%;
      margin-top: 150px;
    }
  }
  .section-2 {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1440px;

    @media (max-width: 768px) {
      width: 80%;
    }
  }
  .section-3 {
    width: 100%;
    margin-top: 200px;
    padding: 100px 0;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      margin-top: 150px;
      padding: 75px 0;
    }

    .top {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;

      @media (max-width: 768px) {
        margin-bottom: 75px;
        flex-direction: column;
        justify-content: flex-start;
      }

      .left {
        position: relative;
        width: 234px;
        margin-right: 100px;

        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 50px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 768px) {
          align-items: center;

          p {
            text-align: justify;
          }
        }

        .topR {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 40px;

          .img {
            width: 21px;
            margin-right: 15px;

            @media (max-width: 768px) {
              width: 18px;
            }
          }

          h3 {
            font-family: 'America', sans-serif;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 8px;
            color: #ffffff;
            text-transform: uppercase;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
        .son {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          margin-top: 40px;

          .img {
            width: 26px;
            margin-right: 10px;
          }

          p {
            font-family: 'America', sans-serif;
            font-size: 16px;
            font-weight: normal;
            color: #ffffff;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .bot {
      width: 80%;
      max-width: 1440px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }

      .item {
        width: 20%;

        @media (max-width: 768px) {
          width: 40%;

          &:nth-child(2n + 1) {
            margin-right: 15px;
          }
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .section-4 {
    width: 100%;
    margin: 200px auto;
    max-width: 1440px;

    @media (max-width: 768px) {
      margin: 150px 0;
    }
  }
  .section-5 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 80%;
      flex-direction: column;
      justify-content: center;
    }

    .left {
      position: relative;
      left: 0;
      top: 0;
      z-index: 2;
      width: 47%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .center {
      position: absolute;
      left: 25%;
      z-index: 5;
      width: 50%;

      @media (max-width: 768px) {
        left: 5%;
        width: 90%;
      }
    }
    .right {
      position: relative;
      right: 0;
      top: 0;
      z-index: 2;
      width: 47%;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  .section-6 {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 90%;
      flex-direction: column;
      justify-content: flex-start;
    }

    .left {
      width: 65%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .right {
      width: 32%;

      @media (max-width: 768px) {
        width: 75%;
        margin-top: 50px;
      }
    }
  }
  .section-7 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    margin-bottom: 100px;
    max-width: 1440px;

    .img {
      width: 70%;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
  .section-8 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 65%;
    }

    .item {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        width: 100%;
      }

      p {
        margin-top: 85px;

        @media (max-width: 768px) {
          margin-top: 50px;
          text-align: justify;
        }
      }
    }
    .reverse {
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 50px;
      }

      p {
        margin-bottom: 85px;

        @media (max-width: 768px) {
          margin-bottom: 50px;
        }
      }
    }
  }
  .section-9 {
    margin: 150px auto 100px auto;
    max-width: 1440px;

    @media (max-width: 768px) {
      margin: 100px 0 75px 0;

      p {
        text-align: justify;
      }
    }
  }
  .section-10 {
    width: 45%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
`

// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledUbisoft } from './Ubisoft.styled'

// Images
import UbisoftImg from '../../Assets/Img/Ubisoft/Ubisoft.webp'
import Img1 from '../../Assets/Img/Ubisoft/ubi-011.png'
import Img2 from '../../Assets/Img/Ubisoft/ubi-010.png'
import Img3 from '../../Assets/Img/Ubisoft/ubi-09.png'
import Img4 from '../../Assets/Img/Ubisoft/ubi-08.png'
import Img5 from '../../Assets/Img/Ubisoft/ubi-07.webp'
import Img6 from '../../Assets/Img/Ubisoft/ubi-06.webp'
import Img7 from '../../Assets/Img/Ubisoft/ubi-05.png'
import Img8 from '../../Assets/Img/Ubisoft/ubi-02.png'
import Img9 from '../../Assets/Img/Ubisoft/ubi-04.png'
import Img10 from '../../Assets/Img/Ubisoft/ubi-03.png'
import Img11 from '../../Assets/Img/Ubisoft/ubi-01.png'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniBIOTHERM.png'
import Footer2 from '../../Assets/Img/Footer/MiniCAVAILLES.png'
import Footer3 from '../../Assets/Img/Footer/MiniYSL.png'

export const Ubisoft = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledUbisoft ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={350}
            initialHeight={353}
            background={UbisoftImg}
            title="Ubisoft"
            subtitle="intranet"
            para={
              'Redesign of the Ubisoft intranet for\na call tenders with @Cosavostra agency'
            }
          />
          <div className="container-project">
            <Title title={'homepage'} />
            <div className="section-1">
              <img src={Img1} alt="" />
            </div>
            <div className="section-2">
              <div className="left">
                <Title subTitle={'first visit\n(onboarding)'} />
                <img src={Img2} alt="" />
              </div>
              <div className="right">
                <Title subTitle={`from the\nsecond visit`} />
                <img src={Img3} alt="" />
              </div>
            </div>
            <Title title={'culture manual'} subTitle={'introduction'} />
            <div className="section-3">
              <img src={Img4} alt="" />
            </div>
            <Title title={'culture manual'} subTitle={'nos valeurs'} />
            <div className="section-4">
              <img src={Img5} alt="" />
            </div>
            <Title title={'culture manual'} subTitle={'notre organisation'} />
            <div className="section-4">
              <img src={Img6} alt="" />
            </div>
            <Title title={'culture manual'} subTitle={'f.a.q.'} />
            <div className="section-4">
              <img src={Img7} alt="" />
            </div>
            <Title title={'menu & search'} />
            <div className="section-1">
              <img src={Img8} alt="" />
            </div>
            <div className="section-5">
              <div className="left">
                <Title subTitle={'first visit (onboarding)'} />
                <img src={Img9} alt="" />
              </div>
              <div className="right">
                <Title subTitle={'from the second visit'} />
                <img src={Img10} alt="" />
              </div>
            </div>
            <Title title={'content page'} subTitle={'notre organisation'} />
            <div className="section-4">
              <img src={Img11} alt="" />
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/BiothermLifePlankton"
          title1="Biotherm"
          text1="LIFE PLANKTON™"
          lock2={true}
          img2={Footer2}
          link2="/RogeCavaillesDigitalBrandbook"
          title2="Rogé Cavaillès"
          text2="digital & brandbook"
          lock3={true}
          img3={Footer3}
          link3="/YvesSaintLaurentWebsite"
          title3="Yves Saint Laurent"
          text3="website"
        />
      </StyledUbisoft>
    </div>
  )
}

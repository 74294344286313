import styled from 'styled-components'

export const StyledTitle = styled.h2`
  font-family: 'America', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 8px;
  text-align: center;
  margin-top: 200px;
  white-space: pre-wrap;
  line-height: 150%;
  margin-bottom: ${(props) => props.marginTitle};

  @media (max-width: 768px) {
    margin-top: 150px;
    font-size: 12px;
    margin-bottom: calc(${(props) => props.marginTitle} * 0.75);
  }
`
export const StyledSubTitle = styled.h3`
  font-family: 'America', sans-serif;
  font-size: 8px;
  font-weight: normal;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3.2px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 55px;
  margin-top: ${(props) => props.margin};
  line-height: 130%;
  white-space: pre-wrap;

  @media (max-width: 768px) {
    margin-bottom: 45px;
    font-size: 8px;
    margin-top: calc(${(props) => props.margin} * 0.75);
  }
`

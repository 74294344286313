import styled from 'styled-components'

export const StyledBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0f0f0f;

  .circle {
    position: absolute;
    width: 600px;
    height: 600px;
    left: calc(50% - 300px);
    top: calc(50% - 300px);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.45);
    opacity: 0.25;

    @media (max-width: 767px) {
      width: 400px;
      height: 400px;
      left: calc(50% - 200px);
      top: calc(50% - 200px);
    }
    @media (max-width: 500px) {
      width: 300px;
      height: 300px;
      left: calc(50% - 150px);
      top: calc(50% - 150px);
    }
  }

  .line {
    position: relative;
    width: 0.8px;
    height: 100%;
    left: calc(50% - 0.4px);
    top: 0;
    background-color: rgba(255, 255, 255, 0.45);
    opacity: 0.25;
  }
`

// Dependencies
import React from 'react'
import { FooterItem } from './FooterItem'

// Styles
import { StyledFooter } from './Footer.styled'

export const Footer = (props) => {
  return (
    <StyledFooter>
      <div className="footer">
        <FooterItem
          lock={props.lock1}
          img={props.img1}
          link={props.link1}
          title={props.title1}
          text={props.text1}
        />
        <FooterItem
          lock={props.lock2}
          img={props.img2}
          link={props.link2}
          title={props.title2}
          text={props.text2}
        />
        <FooterItem
          lock={props.lock3}
          img={props.img3}
          link={props.link3}
          title={props.title3}
          text={props.text3}
        />
      </div>
    </StyledFooter>
  )
}

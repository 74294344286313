// Dependencies
import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import { Text } from '../../Components/Text/Text'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledLifePlankton } from './LifePlankton.styled'

// Images
import LifePlanktonImg from '../../Assets/Img/LifePlankton/LifePlanktonImg.webp'
import Img1 from '../../Assets/Img/LifePlankton/img-1.png'
import Img2 from '../../Assets/Img/LifePlankton/img-2.webp'
import Img3 from '../../Assets/Img/LifePlankton/img-3.png'
import Img4 from '../../Assets/Img/LifePlankton/img-4.png'
import Img5 from '../../Assets/Img/LifePlankton/img-5.png'
import Img6 from '../../Assets/Img/LifePlankton/img-6.png'
import Img7 from '../../Assets/Img/LifePlankton/img-7.png'
import Img8 from '../../Assets/Img/LifePlankton/img-8.png'
import Img9 from '../../Assets/Img/LifePlankton/img-9.png'
import Img10 from '../../Assets/Img/LifePlankton/img-10.png'
import Img11 from '../../Assets/Img/LifePlankton/img-11.png'
import Img12 from '../../Assets/Img/LifePlankton/img-12.png'
import Img13 from '../../Assets/Img/LifePlankton/img-13.png'
import Gif from '../../Assets/Img/LifePlankton/sound.gif'
import camera from '../../Assets/Img/LifePlankton/video-camera.png'
import Video1 from '../../Assets/Img/LifePlankton/EFFET 1_1.mp4'
import Video2 from '../../Assets/Img/LifePlankton/EFFET 2_1.mp4'
import Video3 from '../../Assets/Img/LifePlankton/EFFET 3_1.mp4'
import Video4 from '../../Assets/Img/LifePlankton/EFFET 4_1.mp4'
import Video5 from '../../Assets/Img/LifePlankton/FULL PROGRAMME.mp4'
import Video6 from '../../Assets/Img/LifePlankton/LPLBIO.mp4'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniASIANMARKET.png'
import Footer2 from '../../Assets/Img/Footer/MiniUBISOFT.png'
import Footer3 from '../../Assets/Img/Footer/MiniBeclou.png'

export const LifePlankton = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const video = useRef()
  const page = useRef()
  const scrollContainer = useRef()

  // States
  const [son, setSon] = useState(false)

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const gestionSon = () => {
    son
      ? (setSon(false), (video.current.muted = true))
      : (setSon(true), (video.current.muted = false))
  }

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledLifePlankton ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={390}
            initialHeight={342}
            background={LifePlanktonImg}
            title="Biotherm"
            subtitle="LIFE PLANKTON™"
            para={
              'I conceptualized and crafted an immersive digital experience showcasing product benefits.\nI focused on a scientific look and feel,\nand deployed it on social assets.'
            }
          />
          <div className="container-projectLife">
            <div className="section-1">
              <img src={Img1} alt="" />
            </div>
            <Text
              width={400}
              align={'center'}
              text={
                "Elixir is the fantastic combination of nature and science. It helps skin to protect and regenerate itself thanks to the power of its main ingredient Life Plankton™. Bringing skin's youth back to life. With Life."
              }
            />
            <div className="section-2">
              <video
                src={Video6}
                autoPlay
                loop
                muted
                playsInline
                type="video/mp4"
              ></video>
            </div>
            <div className="section-3">
              <div className="top">
                <div className="left">
                  <video
                    ref={video}
                    src={Video5}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/mp4"
                  ></video>
                </div>
                <div className="right">
                  <div className="topR">
                    <div className="img">
                      <img src={camera} alt="" />
                    </div>
                    <h3>wechat mini site</h3>
                  </div>
                  <Text
                    width={314}
                    align={'left'}
                    text={
                      'An augmented version of the tvc : The purpose of the elixir of life website is to introduce and educate our customers about elixir and its actions on skin making bioscience appealing and snackable. We create a digital gaming experience built around the ELIXIR of Life actions on skin, showcasing the bioscience behind our product like never before.'
                    }
                  />
                  <div className="son" onClick={gestionSon}>
                    <div className="img">
                      <img src={Gif} alt="" />
                    </div>
                    <p>
                      <span>Turn on the sound</span> for a better experience
                    </p>
                  </div>
                </div>
              </div>
              <div className="bot">
                <div className="item">
                  <video
                    src={Video1}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/mp4"
                  ></video>
                </div>
                <div className="item">
                  <video
                    src={Video2}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/mp4"
                  ></video>
                </div>
                <div className="item">
                  <video
                    src={Video3}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/mp4"
                  ></video>
                </div>
                <div className="item">
                  <video
                    src={Video4}
                    autoPlay
                    loop
                    muted
                    playsInline
                    type="video/mp4"
                  ></video>
                </div>
              </div>
            </div>
            <div className="section-4">
              <img src={Img2} alt="" />
            </div>
            <div className="section-5">
              <div className="left">
                <img src={Img4} alt="" />
              </div>
              <div className="center">
                <img src={Img3} alt="" />
              </div>
              <div className="right">
                <img src={Img5} alt="" />
              </div>
            </div>
            <Title title={'wechat & tmall pages'} />
            <div className="section-6">
              <div className="left">
                <img src={Img6} alt="" />
              </div>
              <div className="right">
                <img src={Img7} alt="" />
              </div>
            </div>
            <Title title={'event idea in the\ncontinuity of the mini-site'} />
            <div className="section-7">
              <Text
                width={314}
                align={'center'}
                text={
                  'We build and experience around elixir, where people will enjoy and discover the mysterious product, Its bioscience universe, through metaphors of its actions, touching, seeing, feeling it. This unforgettable premiere will make them fall in love with bioscience at the same time. We will invite journalists, influencers and pop stars to the life room experience launch.'
                }
              />
              <div className="img">
                <img src={Img8} alt="" />
              </div>
            </div>
            <div className="section-8">
              <div className="item">
                <img src={Img9} alt="" />
                <Text
                  width={177}
                  align={'center'}
                  text={
                    'People will start to experience the 1st action the Elixir of Life has on skin : PROTECTION. Walking under the rain, they will not get wet, they will be protected.'
                  }
                />
              </div>
              <div className="item reverse">
                <Text
                  width={177}
                  align={'center'}
                  text={
                    'People will then see the 2nd action the Elixir of Life has on skin : REGENERATION. Once inside, the room will light up creating an infinite feeling mimicking the regeneration process.'
                  }
                />
                <img src={Img10} alt="" />
              </div>
              <div className="item">
                <img src={Img11} alt="" />
                <Text
                  width={177}
                  align={'center'}
                  text={
                    'People will sense the 3rd action the Elixir of Life has on skin : PLUMPNESS. They will walk in a room full of balloons symbolizing the plumpness effect the Hyaluronic Acid has on skin cells.'
                  }
                />
              </div>
              <div className="item reverse">
                <Text
                  width={177}
                  align={'center'}
                  text={
                    'People will live the 4th action the Elixir of Life has on skin : RADIANCE. They will enter a complete dark room. As they walk, the room will light up under their feet, as if they were radiating with light and Life.'
                  }
                />
                <img src={Img12} alt="" />
              </div>
            </div>
            <div className="section-9">
              <Text
                width={465}
                align={'center'}
                text={
                  'People will end their journey in the Elixir of Life SKIN LAB. They will be able to try the product, learn how-to use it and discover the whole Life Plankton™ range. Biotherm experts will introduce them to the brand story, giving skin advices and Skin Life Line tests.'
                }
              />
            </div>
            <div className="section-10">
              <img src={Img13} alt="" />
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/YvesSaintLaurentAsianDigitalMarket"
          title1="Yves Saint Laurent"
          text1="asian market"
          lock2={true}
          img2={Footer2}
          link2="/UbisoftIntranet"
          title2="Ubisoft"
          text2="intranet"
          lock3={false}
          img3={Footer3}
          link3="/BeclouApp"
          title3="Beclou"
          text3="app"
        />
      </StyledLifePlankton>
    </div>
  )
}

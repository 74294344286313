// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { StyledIllustrations } from './llustrations.styled'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Images
import Instagram from '../../Assets/Img/Illustratons/instagram.svg'
import Background from '../../Assets/Img/Illustratons/illu.png'
import image1 from '../../Assets/Img/Illustratons/1.png'
import image2 from '../../Assets/Img/Illustratons/2.png'
import imageLink from '../../Assets/Img/Illustratons/group-3.png'
import image4 from '../../Assets/Img/Illustratons/4.png'
import image5 from '../../Assets/Img/Illustratons/5.png'
import image6 from '../../Assets/Img/Illustratons/6.png'
import image7 from '../../Assets/Img/Illustratons/7.png'
import image8 from '../../Assets/Img/Illustratons/8.png'
import image9 from '../../Assets/Img/Illustratons/9.png'
import image10 from '../../Assets/Img/Illustratons/10.png'
import image11 from '../../Assets/Img/Illustratons/11.png'
import image12 from '../../Assets/Img/Illustratons/12.png'
import image13 from '../../Assets/Img/Illustratons/13.png'
import image14 from '../../Assets/Img/Illustratons/14.png'
import image15 from '../../Assets/Img/Illustratons/15.png'
import image16 from '../../Assets/Img/Illustratons/16.png'
import image17 from '../../Assets/Img/Illustratons/17.png'
import image18 from '../../Assets/Img/Illustratons/18.png'
import image19 from '../../Assets/Img/Illustratons/19.png'
import image20 from '../../Assets/Img/Illustratons/20.png'
import image21 from '../../Assets/Img/Illustratons/21.png'
import image22 from '../../Assets/Img/Illustratons/22.png'
import image23 from '../../Assets/Img/Illustratons/23.png'
import image24 from '../../Assets/Img/Illustratons/24.png'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniYSL.png'
import Footer2 from '../../Assets/Img/Footer/MiniBIOTHERM.png'
import Footer3 from '../../Assets/Img/Footer/MiniLIBRE.png'

export const Illustrations = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const images = [
    image1,
    image2,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
  ]
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledIllustrations
        ref={scrollContainer}
        id="dismissNavbar"
        Background={Background}
      >
        <div className="header">
          <div className="text">
            <h1>Illustrations</h1>
            <a
              href="https://www.instagram.com/alicejstnart/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="img">
                <img src={Instagram} alt="Instagram" />
              </div>
              <h2>
                See more on
                <br />
                Instagram
              </h2>
              <p>@alicejstart</p>
            </a>
          </div>
        </div>
        <div className="container">
          <div className="container-image">
            {images.map((item, index) => {
              return (
                <div key={index} className="image">
                  <img src={item} alt="illustration de Alice Jestin" />
                </div>
              )
            })}
            <div className="image">
              <img src={imageLink} alt="illustration de Alice Jestin" />
            </div>
          </div>
        </div>
        <Footer
          lock1={true}
          img1={Footer1}
          link1="/YvesSaintLaurentWebsite"
          title1="Yves Saint Laurent"
          text1="website"
          lock2={false}
          img2={Footer2}
          link2="/BiothermLifePlankton"
          title2="Biotherm"
          text2="life plankton™"
          lock3={false}
          img3={Footer3}
          link3="/YvesSaintLaurentLibreRange"
          title3="Yves Saint Laurent"
          text3="libre range"
        />
      </StyledIllustrations>
    </div>
  )
}

import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  img, 
  video {
    width: 100%;
    height: auto;
  }
  html.has-scroll-smooth {
    overflow: hidden; 
  }
  html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
  }
  .has-scroll-smooth body {
    overflow: hidden; 
  }
  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh; 
  }
  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0; 
  }
  .c-scrollbar:hover {
    transform: scaleX(1.45); 
  }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; 
  }
  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab; 
  }
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; 
  }
  a {
    text-decoration: none;
  }
  .lock {
    cursor: pointer;
  }
  .deleteHover {
    text-decoration: none;
    border-bottom: none;
    cursor: default !important;
    &:hover {
      border-bottom: none !important;
    }
  }
  .container-project {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .ReactModal__Overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ReactModal__Content {
    position: relative !important;
    z-index: 9999;
    width: 550px;
    height: 350px;
    margin: 0 auto;
    border: none !important;
    background-color: #0f0f0f !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch !important;
    border-radius: 2px !important;
    outline: none !important;
    padding: 20px !important;
    inset: 0 !important;

    @media (max-width: 767px) {
      width: 85%;
      height: 350px;
    }
  }
  .Page {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    position: absolute;
    width: 7px;
    background-color: #0f0f0f;
  }
  ::-webkit-scrollbar-track {
    background-color: #0f0f0f;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #303030;
    opacity: 1;
    border-radius: 7px;
  }
`
export default GlobalStyles

import { createGlobalStyle } from 'styled-components'

import AmericaBoldOtf from '../Assets/Fonts/GT-America-Bold.otf'
import AmericaBoldTtf from '../Assets/Fonts/GT-America-Bold.ttf'
import AmericaRegOtf from '../Assets/Fonts/GT-America-Regular.otf'
import AmericaRegTtf from '../Assets/Fonts/GT-America-Regular.ttf'
import AvantGarde from '../Assets/Fonts/ITCAvantGardeStd-Bk.otf'
import AvantGardeCn from '../Assets/Fonts/ITCAvantGardeStd-BkCn.otf'
import Palatino from '../Assets/Fonts/Palatino.ttc'
import PradaOtf from '../Assets/Fonts/PradaTypeface-TestThree.otf'
import PradaTtf from '../Assets/Fonts/PradaTypeface-TestThree.ttf'

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: "America";
    font-style: normal;
    font-weight: bold;
    src: url(${AmericaBoldOtf}) format("otf"),
    url(${AmericaBoldTtf}) format("ttf");
  }
  @font-face {
    font-family: "America";
    font-style: normal;
    font-weight: normal;
    src: url(${AmericaRegOtf}) format("otf"),
    url(${AmericaRegTtf}) format("ttf");
  }
  @font-face {
    font-family: "AvantGarde";
    font-style: normal;
    font-weight: normal;
    src: url(${AvantGarde}) format("otf");
  }
  @font-face {
    font-family: "AvantGardeCn";
    font-style: normal;
    font-weight: normal;
    src: url(${AvantGardeCn}) format("otf");
  }
  @font-face {
    font-family: "Palatino";
    font-style: normal;
    font-weight: normal;
    src: url(${Palatino}) format("ttc");
  }
  @font-face {
    font-family: "Prada";
    font-style: normal;
    font-weight: normal;
    src: url(${PradaOtf}) format("otf"),
    url(${PradaTtf}) format("ttf");
  }
`
export default GlobalFonts

import styled from 'styled-components'

export const StyledLogin = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .image {
      width: 15px;
      height: 15px;
      padding: 10px;
      opacity: 0.6;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
  .content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .image {
      width: 20px;

      @media (max-width: 500px) {
        width: 15px;
      }
    }

    .text {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0;

      p {
        font-family: 'Palatino', sans-serif;
        font-size: 23px;
        font-weight: normal;
        color: #ffffff;
        line-height: 120%;

        a {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }
  form {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #424242;
    padding: 5px;

    input {
      background-color: transparent;
      border: none;
      outline: none;
      font-family: 'America', sans-serif;
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 2.5px;
      padding: 5px;
    }
    input[type='password'] {
      width: 250px;
    }

    span {
      display: ${(props) => props.displayError};
      position: absolute;
      bottom: -20px;
      left: 0;
      font-family: 'America', sans-serif;
      font-size: 12px;
      color: #ff0000;
    }
  }
`

import styled from 'styled-components'

export const StyledLibre = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left {
      width: 25%;

      @media (max-width: 768px) {
        width: 60%;
      }
    }

    .right {
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: 70px;

      @media (max-width: 768px) {
        width: 80%;
        margin-top: 50px;
      }

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 150px;

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 75px;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        .item {
          width: 45%;

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .bot {
        width: 100%;

        @media (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
  .section-2 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .right {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .left {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        width: 100%;
      }

      .item {
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .section-3 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .item {
      width: 20%;

      @media (max-width: 768px) {
        width: 64%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .section-4 {
    width: 50%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .section-5 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 90%;
    }

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .item {
        width: 45%;

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }

    .item {
      width: 43%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .section-6 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 95%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .item {
      width: 25%;

      @media (max-width: 768px) {
        width: 45%;
      }
    }
  }
`

// Dependencies
import React from 'react'

// Styles
import { StyledTitle, StyledSubTitle } from './Title.styled'

export const Title = (props) => {
  return (
    <>
      {props.title ? (
        <StyledTitle marginTitle={props.subTitle ? '0px' : '100px'}>
          {props.title}
        </StyledTitle>
      ) : (
        <></>
      )}
      {props.subTitle ? (
        <StyledSubTitle margin={props.title ? '15px' : '100px'}>
          {props.subTitle}
        </StyledSubTitle>
      ) : (
        <></>
      )}
    </>
  )
}

// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledRoge } from './Roge.styled'

// Images
import RogeImg from '../../Assets/Img/Roge/RogeImg.webp'
import Img1 from '../../Assets/Img/Roge/img-1.webp'
import Img2 from '../../Assets/Img/Roge/img-2.png'
import Img3 from '../../Assets/Img/Roge/img-3.png'
import Img4 from '../../Assets/Img/Roge/img-4.png'
import Img5 from '../../Assets/Img/Roge/img-5.png'
import Img6 from '../../Assets/Img/Roge/img-6.png'
import Img7 from '../../Assets/Img/Roge/img-7.png'
import Img8 from '../../Assets/Img/Roge/img-8.png'
import Img9 from '../../Assets/Img/Roge/img-9.png'
import Img10 from '../../Assets/Img/Roge/img-10.png'
import Img11 from '../../Assets/Img/Roge/img-11.png'
import Img12 from '../../Assets/Img/Roge/img-12.png'
import Img13 from '../../Assets/Img/Roge/img-13.png'
import Img14 from '../../Assets/Img/Roge/img-14.png'
import Img15 from '../../Assets/Img/Roge/img-15.png'
import Video1 from '../../Assets/Img/Roge/video-1.mp4'
import Video2 from '../../Assets/Img/Roge/video-2.mp4'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniPRADA.png'
import Footer2 from '../../Assets/Img/Footer/MiniUBISOFT.png'
import Footer3 from '../../Assets/Img/Footer/MiniBeclou.png'

export const Roge = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledRoge ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={374}
            initialHeight={366}
            background={RogeImg}
            title="Rogé Cavaillès"
            subtitle="ui - social media - layout"
            para={
              'Participation in the redesign of the brand image:\nWebsite redesign, Brandbook, production of various documents and digital assets'
            }
          />
          <div className="container-project">
            <Title title={'brandbook'} />
            <div className="section-1">
              <img src={Img1} alt="" />
            </div>
            <div className="section-2">
              <div className="top">
                <div className="big">
                  <img src={Img2} alt="" />
                </div>
                <div className="small">
                  <img src={Img3} alt="" />
                </div>
              </div>
              <div className="center">
                <img src={Img4} alt="" />
              </div>
              <div className="top">
                <div className="small">
                  <img src={Img5} alt="" />
                </div>
                <div className="big">
                  <img src={Img6} alt="" />
                </div>
              </div>
            </div>
            <Title title={'website'} subTitle={'homepage'} />
            <div className="section-3">
              <div className="left">
                <img src={Img7} alt="" />
              </div>
              <div className="right">
                <img src={Img8} alt="" />
              </div>
            </div>
            <div className="section-4">
              <div className="item">
                <video
                  src={Video1}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
              <div className="item">
                <video
                  src={Video2}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
            </div>
            <Title title={'website'} subTitle={'product page'} />
            <div className="section-5">
              <div className="left">
                <img src={Img9} alt="" />
              </div>
              <div className="right">
                <img src={Img10} alt="" />
              </div>
            </div>
            <Title title={'website'} subTitle={'menu'} />
            <div className="section-6">
              <img src={Img11} alt="" />
            </div>
            <div className="section-7">
              <div className="item">
                <img src={Img12} alt="" />
              </div>
              <div className="item">
                <img src={Img13} alt="" />
              </div>
            </div>
            <Title title={'insta'} subTitle={'layout'} />
            <div className="section-8">
              <div className="left">
                <img src={Img14} alt="" />
              </div>
              <div className="right">
                <img src={Img15} alt="" />
              </div>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/PradaWebsite"
          title1="Prada"
          text1="website"
          lock2={true}
          img2={Footer2}
          link2="/UbisoftIntranet"
          title2="Ubisoft"
          text2="intranet"
          lock3={false}
          img3={Footer3}
          link3="/BeclouApp"
          title3="Beclou"
          text3="app"
        />
      </StyledRoge>
    </div>
  )
}

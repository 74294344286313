// Dependencies
import React from 'react'

// Styles
import { StyledBackground } from './Background.styled'

export const Backgroud = () => {
  return (
    <StyledBackground>
      <div className="circle"></div>
      <div className="line"></div>
    </StyledBackground>
  )
}

// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledLibre } from './Libre.styled'

// Images
import LibreImg from '../../Assets/Img/Libre/LibreImg.webp'
import Img1 from '../../Assets/Img/Libre/img-1.webp'
import Img2 from '../../Assets/Img/Libre/img-2.png'
import Img3 from '../../Assets/Img/Libre/img-3.png'
import Img4 from '../../Assets/Img/Libre/img-4.webp'
import Img5 from '../../Assets/Img/Libre/img-5.png'
import Img6 from '../../Assets/Img/Libre/libre-06.png'
import Img7 from '../../Assets/Img/Libre/libre-05.png'
import Img8 from '../../Assets/Img/Libre/libre-04.png'
import Img9 from '../../Assets/Img/Libre/img-9.png'
import Img10 from '../../Assets/Img/Libre/img-10.png'
import Img11 from '../../Assets/Img/Libre/img-11.png'
import Img12 from '../../Assets/Img/Libre/img-12.png'
import Img14 from '../../Assets/Img/Libre/libre-016.png'
import Img15 from '../../Assets/Img/Libre/libre-015.png'
import Img16 from '../../Assets/Img/Libre/libre-014.png'
import Img17 from '../../Assets/Img/Libre/img-17.png'
import Video1 from '../../Assets/Img/Libre/media6.mp4'
import Story1 from '../../Assets/Img/Libre/story1.mp4'
import Story2 from '../../Assets/Img/Libre/story2.mp4'
import Story3 from '../../Assets/Img/Libre/story3.mp4'
import Story4 from '../../Assets/Img/Libre/story4.mp4'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniBIOTHERM.png'
import Footer2 from '../../Assets/Img/Footer/MiniSKINDIAG.png'
import Footer3 from '../../Assets/Img/Footer/MiniYSL.png'

export const Libre = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledLibre ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={355}
            initialHeight={333}
            background={LibreImg}
            title="Yves Saint Laurent"
            subtitle="campaign digital kit"
            para={
              'During my experience @PublicisLuxe,\nI had the opportunity to work on many digital campaign,\nand create a lot of digital kit. For exemple, i crafted\nproduct pages, e-business pages, newsletters, banners,\ninstagram stories, instagram and facebook posts,\ncarousels, and many other type of social assets.'
            }
          />
          <div className="container-project">
            <Title title={'product page'} />
            <div className="section-1">
              <div className="left">
                <img src={Img1} alt="" />
              </div>
              <div className="right">
                <div className="top">
                  <div className="item">
                    <img src={Img2} alt="" />
                  </div>
                  <div className="item">
                    <img src={Img3} alt="" />
                  </div>
                </div>
                <div className="bot">
                  <img src={Img4} alt="" />
                </div>
              </div>
            </div>
            <Title title={'e - sampling'} />
            <div className="section-2">
              <div className="right">
                <img src={Img5} alt="" />
              </div>
              <div className="left">
                <div className="item">
                  <img src={Img6} alt="" />
                </div>
                <div className="item">
                  <img src={Img7} alt="" />
                </div>
                <div className="item">
                  <img src={Img8} alt="" />
                </div>
              </div>
            </div>
            <Title title={'newsletters'} />
            <div className="section-3">
              <div className="item">
                <img src={Img9} alt="" />
              </div>
              <div className="item">
                <img src={Img10} alt="" />
              </div>
              <div className="item">
                <img src={Img11} alt="" />
              </div>
              <div className="item">
                <img src={Img12} alt="" />
              </div>
            </div>
            <Title title={'wetransfer'} />
            <div className="section-4">
              <video
                src={Video1}
                autoPlay
                loop
                muted
                playsInline
                type="video/mp4"
              ></video>
            </div>
            <Title title={'stories'} />
            <div className="section-6">
              <div className="item">
                <video
                  src={Story1}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
              <div className="item">
                <video
                  src={Story2}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
              <div className="item">
                <video
                  src={Story3}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
              <div className="item">
                <video
                  src={Story4}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
            </div>
            <Title title={'facebook posts'} />
            <div className="section-5">
              <div className="top">
                <div className="item">
                  <img src={Img14} alt="" />
                </div>
                <div className="item">
                  <img src={Img15} alt="" />
                </div>
              </div>
              <div className="item">
                <img src={Img16} alt="" />
              </div>
            </div>
            <Title title={'spotify'} />
            <div className="section-4">
              <img src={Img17} alt="" />
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/BiothermLifePlankton"
          title1="Biotherm"
          text1="LIFE PLANKTON™"
          lock2={true}
          img2={Footer2}
          link2="/BiothermSkinDiagnosis"
          title2="Biotherm"
          text2="skin diagnosis"
          lock3={true}
          img3={Footer3}
          link3="/YvesSaintLaurentWebsite"
          title3="Yves Saint Laurent"
          text3="website"
        />
      </StyledLibre>
    </div>
  )
}

// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import { Footer } from '../../Components/Footer/Footer'
import Navigation from '../../Components/Navbar/Navigation'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledBioSkin } from './BioSkin.styled'

// Images
import BioSkinImg from '../../Assets/Img/BioSkin/BioSkinImg.webp'
import Img1 from '../../Assets/Img/BioSkin/img-1.png'
import Img2 from '../../Assets/Img/BioSkin/img-2.png'
import Img3 from '../../Assets/Img/BioSkin/img-3.png'
import Img4 from '../../Assets/Img/BioSkin/img-4.webp'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniBIOTHERM.png'
import Footer2 from '../../Assets/Img/Footer/MiniCAVAILLES.png'
import Footer3 from '../../Assets/Img/Footer/MiniYSL.png'

export const BioSkin = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledBioSkin ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={350}
            initialHeight={353}
            background={BioSkinImg}
            title="Biotherm"
            subtitle="skin diagnosis"
            para={
              'UI Design\nConsumers can diagnose their skin by taking a photo and answering a questionnaire.\nThe age of their skin is compared to their real age.\nBiotherm then suggests a routine adapted to their needs.\nI first participated in the conception, then realized the design entirely.'
            }
          />
          <div className="container-project">
            <div className="section-1">
              <img src={Img4} alt="" />
            </div>
            <div className="section-2">
              <div className="item">
                <img src={Img1} alt="" />
              </div>
              <div className="item">
                <img src={Img2} alt="" />
              </div>
              <div className="item">
                <img src={Img3} alt="" />
              </div>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/BiothermLifePlankton"
          title1="Biotherm"
          text1="LIFE PLANKTON™"
          lock2={true}
          img2={Footer2}
          link2="/RogeCavaillesDigitalBrandbook"
          title2="Rogé Cavaillès"
          text2="digital & brandbook"
          lock3={true}
          img3={Footer3}
          link3="/YvesSaintLaurentWebsite"
          title3="Yves Saint Laurent"
          text3="website"
        />
      </StyledBioSkin>
    </div>
  )
}

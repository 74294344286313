// Dependencies
import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-router-dom'
import Brand from './Brand'
import BurgerMenu from './BurgerMenu'
import CollapseMenu from './CollapseMenu'

const Navbar = (props) => {
  // Variables
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: sessionStorage.getItem('loader') ? 500 : 7000,
  })
  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 15px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
  })

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand />
          <NavLinks style={linkAnimation}>
            <Link to="/About">about</Link>
            <span className="deleteHover">-</span>
            <Link to="/">projects</Link>
            <span className="deleteHover">-</span>
            <Link to="/Illustrations">illustrations</Link>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
    </>
  )
}
export default Navbar

// Styles
const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 10;
  font-size: 1.4rem;
  pointer-events: all;
`

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 40px 95px;
  justify-content: space-between;
  height: 5rem;
  @media (max-width: 750px) {
    padding: 20px 50px !important;
  }
  @media (max-width: 560px) {
    padding: 10px 30px !important;
  }
`

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  & a,
  & span {
    color: #ffffff;
    font-family: 'America', sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 3.2px;
    font-size: 8px;
    border-bottom: 1px solid transparent;
    margin: 0 1rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

const BurgerWrapper = styled.div`
  margin: auto 0;
  @media (min-width: 1051px) {
    display: none;
  }
`

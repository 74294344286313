// Dependencies
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { gsap } from 'gsap'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'
import AuthService from '../../Hooks/auth'
import Login from '../Login/Login'

// Styles
import { StyledProject } from './Project.styled'

// Images
import cadenas from '../../Assets/Img/cadenas.svg'

// Variables
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }

const Project = (props, ref) => {
  // Hooks
  const { height, width } = useWindowDimensions()

  // Refs
  const login = useRef()
  const turbulence = useRef()
  const image = useRef()
  const text = useRef()
  const title = useRef()
  const element = useRef()
  const el = useRef()

  // States
  const [top, setTop] = useState()
  const [opacity, setOpacity] = useState()
  const [showModeratorBoard, setShowModeratorBoard] = useState()
  const [posx, setPosx] = useState(0)
  const [posy, setPosy] = useState(0)

  // Variables
  var tl = gsap.timeline({ paused: true })

  // Functions
  useEffect(async () => {
    const user = await AuthService.getCurrentUser()
    if (user) {
      setShowModeratorBoard(true)
    } else {
      setShowModeratorBoard(false)
    }
    if (width > 1024) {
      tl.from(image.current, { duration: 1, opacity: 0, y: 50, stagger: 0.6 })
        .to(
          turbulence.current,
          { duration: 2, attr: { baseFrequency: '0 0' }, stagger: 0.2 },
          '<'
        )
        .from(text.current, { duration: 1, opacity: 0, y: 20 }, 0.5)
        .from(title.current, { duration: 1, opacity: 0, y: 50 }, '<')
      tl.pause()
    }
  }, [tl])
  useImperativeHandle(
    ref,
    () => ({
      activeAnim(status) {
        if (status == 'enter') {
          tl.play()
        } else {
          tl.reverse()
        }
      },
    }),
    []
  )
  const handleClick = () => {
    setTop('translate(0px, 0px) !important')
    setOpacity('1 !important')
    setPosx(
      width / 2 -
        el.current.getBoundingClientRect().width / 2 -
        el.current.getBoundingClientRect().left
    )
    setPosy(
      height / 2 -
        el.current.getBoundingClientRect().height / 2 -
        el.current.getBoundingClientRect().top
    )
  }
  const openModal = () => {
    login.current.showModal()
  }

  // Rendering
  return (
    <>
      <svg>
        <filter id={props.name} x="0%" y="0%" width="100%" height="100%">
          <feTurbulence
            baseFrequency="0.01 0.03"
            result="NOISE"
            numOctaves="1"
            id="turbulence"
            ref={turbulence}
          />
          <feDisplacementMap
            in="SourceGraphic"
            in2="NOISE"
            scale="20"
          ></feDisplacementMap>
        </filter>
      </svg>
      {showModeratorBoard || props.lock == 'unlocked' ? (
        <Link to={props.link} onClick={handleClick}>
          {props.direction == 'right' ? (
            <StyledProject
              imageWidth={props.imageWidth}
              imageHeight={props.imageHeight}
              imageMarge={props.imageMarge}
              opacity={opacity}
              transform={top}
              direction="flex-start"
              name={props.name}
              ref={element}
            >
              <div className="left">
                <motion.div className="title" ref={title}>
                  <h2>{props.title}</h2>
                  <p>{props.descr}</p>
                </motion.div>
              </div>
              <div className="right">
                <div className="image">
                  <motion.div
                    className="img"
                    ref={el}
                    exit={{ top: posy + 'px', left: posx + 'px' }}
                    transition={transition}
                  >
                    <motion.img
                      src={props.image}
                      ref={image}
                      transition={transition}
                    />
                  </motion.div>
                  <motion.div className="text" ref={text}>
                    <p>{props.text}</p>
                    <p>{props.date}</p>
                  </motion.div>
                </div>
              </div>
            </StyledProject>
          ) : (
            <StyledProject
              imageWidth={props.imageWidth}
              imageHeight={props.imageHeight}
              imageMarge={props.imageMarge}
              opacity={opacity}
              transform={top}
              direction="flex-end"
              name={props.name}
              ref={element}
            >
              <div className="right">
                <div className="image">
                  <motion.div
                    className="img"
                    ref={el}
                    exit={{ top: posy + 'px', left: posx + 'px' }}
                    transition={transition}
                  >
                    <motion.img
                      src={props.image}
                      ref={image}
                      transition={transition}
                    />
                  </motion.div>
                  <motion.div className="text" ref={text}>
                    <p>{props.text}</p>
                    <p>{props.date}</p>
                  </motion.div>
                </div>
              </div>
              <div className="left">
                <motion.div className="title" ref={title}>
                  <h2>{props.title}</h2>
                  <p>{props.descr}</p>
                </motion.div>
              </div>
            </StyledProject>
          )}
        </Link>
      ) : (
        <>
          <Login ref={login} />
          <div className="lock" onClick={openModal}>
            {props.direction == 'right' ? (
              <StyledProject
                imageWidth={props.imageWidth}
                imageHeight={props.imageHeight}
                imageMarge={props.imageMarge}
                direction="flex-start"
                name={props.name}
                ref={element}
              >
                <div className="left">
                  <div className="title" ref={title}>
                    <div className="img">
                      <img src={cadenas} alt="" />
                    </div>
                    <h2>{props.title}</h2>
                    <p>{props.descr}</p>
                  </div>
                </div>
                <div className="right">
                  <div className="image">
                    <div className="img" ref={el}>
                      <img src={props.image} ref={image} />
                    </div>
                    <div className="text" ref={text}>
                      <p>{props.text}</p>
                      <p>{props.date}</p>
                    </div>
                  </div>
                </div>
              </StyledProject>
            ) : (
              <StyledProject
                imageWidth={props.imageWidth}
                imageHeight={props.imageHeight}
                imageMarge={props.imageMarge}
                direction="flex-end"
                name={props.name}
                ref={element}
              >
                <div className="right">
                  <div className="image">
                    <div className="img" ref={el}>
                      <img
                        src={props.image}
                        ref={image}
                        transition={transition}
                      />
                    </div>
                    <div className="text" ref={text}>
                      <p>{props.text}</p>
                      <p>{props.date}</p>
                    </div>
                  </div>
                </div>
                <div className="left">
                  <div className="title" ref={title}>
                    <div className="img">
                      <img src={cadenas} alt="" />
                    </div>
                    <h2>{props.title}</h2>
                    <p>{props.descr}</p>
                  </div>
                </div>
              </StyledProject>
            )}
          </div>
        </>
      )}
    </>
  )
}
// export default Project
export default forwardRef(Project)

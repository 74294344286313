import styled from 'styled-components'

export const StyledRoge = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 100%;
    margin-bottom: 100px;
  }
  .section-2 {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .big {
        width: 60%;

        @media (max-width: 768px) {
          width: 90%;
        }
      }

      .small {
        width: 33%;

        @media (max-width: 768px) {
          width: 90%;

          &:first-child {
            margin-bottom: 20px;
          }
          &:last-child {
            margin-top: 20px;
          }
        }
      }
    }

    .center {
      width: 40%;
      margin: 100px 0;

      @media (max-width: 768px) {
        width: 90%;
        margin: 20px 0;
      }
    }
  }
  .section-3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;

    @media (max-width: 768px) {
      width: 95%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .left {
      width: 62%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .right {
      width: 38%;
      margin-top: 90px;

      @media (max-width: 768px) {
        width: 65%;
        margin-top: 50px;
      }
    }
  }
  .section-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 45%;
    margin-top: 200px;

    @media (max-width: 768px) {
      width: 85%;
      margin-top: 150px;
    }

    .item {
      width: 100%;

      &:first-child {
        margin-bottom: 100px;

        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .section-5 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .right {
      width: 45%;
      margin-top: 70px;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  .section-6 {
    width: 80%;
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
  .section-7 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .item {
      width: 45%;

      @media (max-width: 768px) {
        width: 100%;

        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  .section-8 {
    position: relative;
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 90%;
    }

    .left {
      width: 55%;
      position: relative;
      z-index: 2;
      left: 15%;
      top: 0;

      @media (max-width: 768px) {
        left: 5%;
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 45%;
      z-index: 4;

      @media (max-width: 768px) {
        width: 55%;
      }
    }
  }
`

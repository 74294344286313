// Dependencies
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import ReactModal from 'react-modal'
import axios from 'axios'

// Styles
import { StyledLogin } from './Login.styled'

// Images
import fermer from '../../Assets/Img/letter-x.png'
import cadenas from '../../Assets/Img/cadenas.svg'

const Login = (props, ref) => {
  // States
  const [showModal, setShowModal] = useState(false)
  const [code, setCode] = useState('')
  const [displayError, setDisplayError] = useState('none')
  const [showModeratorBoard, setShowModeratorBoard] = useState(null)

  // Functions
  const handleChange = (e) => {
    setCode(e.target.value)
    setDisplayError('none')
  }
  const handleClose = () => {
    setShowModal(false)
  }
  const access = (e) => {
    e.preventDefault()
    setShowModeratorBoard(false)
    const connexion = {
      password: code,
    }
    axios
      .post('/api/auth/signin', connexion)
      .then((res) => {
        if (res.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(res.data))
          setShowModal(true)
          window.location.reload(true)
        }
      })
      .catch((err) => {
        console.log(err)
        setDisplayError('block')
      })
  }
  useImperativeHandle(
    ref,
    () => ({
      showModal() {
        setShowModal(true)
      },
    }),
    []
  )

  // Rendering
  return (
    <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        overlay: {
          position: 'fixed',
          zIndex: 9999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <StyledLogin
        displayError={displayError}
        showModeratorBoard={showModeratorBoard}
      >
        <div className="header">
          <div className="image" onClick={handleClose}>
            <img src={fermer} alt="bouton fermer" />
          </div>
        </div>
        <div className="content">
          <div className="image">
            <img src={cadenas} alt="cadenas" />
          </div>
          <div className="text">
            <p>This portfolio is locked.</p>
            <p>
              Please <a href="mailto:jestin.alice@gmail.com">contact me</a> to
              get access.
            </p>
          </div>
        </div>
        <form onSubmit={access}>
          <input
            type="password"
            name="code"
            value={code}
            onChange={handleChange}
            placeholder="PASSWORD"
          />
          <input type="submit" value="ENTER" className="btn" />
          <span>Please make sure to enter the correct password</span>
        </form>
      </StyledLogin>
    </ReactModal>
  )
}

export default forwardRef(Login)

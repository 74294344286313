import styled from 'styled-components'

export const StyledProject = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 220px;

  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }
  @media (max-width: 767px) {
    flex-direction: ${(props) =>
      props.direction === 'flex-end' ? 'column' : 'column-reverse'};
  }
  @media (max-width: 767px) {
    margin-bottom: 75px;
  }
  @media (max-width: 500px) {
    margin-bottom: 50px;
  }

  svg {
    position: absolute;
  }

  .left,
  .right {
    position: relative;
    width: 50%;
    @media (max-width: 767px) {
      width: 75%;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.direction};
  }

  .title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .img {
      width: 11px;
      margin-bottom: 5px;
    }

    h2 {
      width: 100%;
      text-align: center;
      font-family: 'Palatino', sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 30px;
      color: #ffffff;
    }
    p {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }

  .image {
    position: relative;
    left: ${(props) => props.imageMarge}px;
    width: ${(props) => props.imageWidth}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 1200px) {
      left: ${(props) => props.imageMarge * 0.25}px;
    }
    @media (max-width: 1024px) {
      left: 0;
      width: ${(props) => props.imageWidth * 0.85}px;
    }
    @media (max-width: 850px) {
      width: ${(props) => props.imageWidth * 0.75}px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }

    .img {
      width: 100%;
      filter: url(#${(props) => props.name});
      width: ${(props) => props.imageWidth}px;
      height: ${(props) => props.imageHeight}px;
      position: relative;
      left: -10px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 1024px) {
        height: ${(props) => props.imageHeight * 0.85}px;
        width: ${(props) => props.imageWidth * 0.85}px;
        filter: none;
      }
      @media (max-width: 850px) {
        height: ${(props) => props.imageHeight * 0.75}px;
        width: ${(props) => props.imageWidth * 0.75}px;
      }
      @media (max-width: 767px) {
        width: 100%;
        height: 400px;
      }
      @media (max-width: 600px) {
        height: 350px;
      }
      @media (max-width: 500px) {
        height: 300px;
      }
      @media (max-width: 400px) {
        height: 280px;
      }
      @media (max-width: 350px) {
        height: 250px;
      }

      img {
        height: 100%;
        width: auto;
        opacity: ${(props) => props.opacity};
        transform: ${(props) => props.transform};
      }
    }

    .text {
      position: relative;
      width: calc(100% - 7px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  p {
    font-family: 'America', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    color: #ffffff;
  }
`

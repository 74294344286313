// Dependencies
import axios from 'axios'

// Variables
const API_URL = '/api/auth/'

class AuthService {
  async login(password) {
    const response = await axios.post(API_URL + 'signin', {
      password: password,
    })
    if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  }

  logout() {
    localStorage.removeItem('user')
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }
}

export default new AuthService()

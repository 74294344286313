// Dependencies
import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { Backgroud } from '../../Components/Background/Backgroud'
import Project from '../../Components/Project/Project'
import { StyledHome, StyledBg } from './Home.styled'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import Login from '../../Components/Login/Login'
import { smoothScroll } from '../../Hooks/smoothScroll'
import { useLocation } from 'react-router-dom'
import Navigation from '../../Components/Navbar/Navigation'
import Social from '../../Components/Social/Social'
import { HitMe } from '../../Components/HitMe/HitMe'

// Images
import RAL from '../../Assets/Img/Home/RAL.webp'
import Prada from '../../Assets/Img/Home/Prada.webp'
import Beclou from '../../Assets/Img/Home/Beclou.webp'
import Libre from '../../Assets/Img/Home/Libre.webp'
import LifePlankton from '../../Assets/Img/Home/LifePlankton.webp'
import Roge from '../../Assets/Img/Home/Roge.webp'
import Ubisoft from '../../Assets/Img/Home/Ubisoft.webp'
import YSL from '../../Assets/Img/Home/YSL.webp'
import BioSkin from '../../Assets/Img/Home/BioSkin.webp'

// Plugins
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export const Home = () => {
  // Refs
  const title = useRef()
  const container = useRef()
  const line = useRef()
  const circle = useRef()
  const login = useRef()
  const projectRefs = useRef([])
  projectRefs.current = []
  const projectsChild = useRef([])
  projectsChild.current = []

  // Location
  const { pathname } = useLocation()

  // Functions
  useEffect(() => {
    if (sessionStorage.getItem('loader') === null) {
      sessionStorage.setItem('loader', false)
      document.getElementById('counter').style.display = 'flex'
      var Count = { val: 0 },
        NewVal = 100

      gsap
        .timeline({ paused: true })
        .to(Count, 5, {
          val: NewVal,
          roundProps: 'val',
          onUpdate: function () {
            document.getElementById('valCounter').innerHTML = Count.val
          },
          display: 'none',
        })
        .to('#counter', { y: -30, opacity: 0, duration: 1 })
        .from(line.current, { height: 0, duration: 4 }, 0)
        .from(circle.current, { drawSVG: '50% 50%', duration: 1.5 }, 0.75)
        .from(title.current, { y: -30, opacity: 0, duration: 1.5 }, 6)
        .play()
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    smoothScroll(container.current)
  }, [pathname])
  useEffect(() => {
    smoothScroll(container.current)

    gsap.utils.toArray(projectRefs.current).forEach(function (item, index) {
      ScrollTrigger.create({
        id: 'scrollTrigger',
        trigger: item,
        //scroller: container.current,
        start: 'top 60%',
        end: 'bottom 20%',
        // markers: true,
        onEnter: function () {
          Object.values(projectsChild.current)[index].activeAnim('enter')
        },
        onLeave: function () {
          Object.values(projectsChild.current)[index].activeAnim('leave')
        },
        onEnterBack: function () {
          Object.values(projectsChild.current)[index].activeAnim('enter')
        },
        onLeaveBack: function () {
          Object.values(projectsChild.current)[index].activeAnim('leave')
        },
      })
    })
  }, [projectRefs, projectsChild])
  const addToProjectRef = (el) => {
    if (el && !projectRefs.current.includes(el)) {
      projectRefs.current.push(el)
    }
  }
  const addToprojectsChild = (el) => {
    if (el && !projectsChild.current.includes(el)) {
      projectsChild.current.push(el)
    }
  }

  // Rendering
  return (
    <>
      <Backgroud />
      <Navigation />
      <Social />
      <StyledBg>
        <div id="counter">
          <p id="valCounter">0</p>
          <p>&nbsp;%</p>
        </div>
        <svg
          version="1.1"
          baseProfile="tiny"
          id="circleSvg"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          x="calc(50% - 300px)"
          y="calc(50% - 300px)"
          width="600px"
          height="600px"
          viewBox="0 0 600 600"
          space="preserve"
          preserveAspectRatio="none"
          style={{
            position: 'fixed',
          }}
        >
          <path
            ref={circle}
            id="circleSvg2"
            d="M0,300a300,300 0 1,0 600,0a300,300 0 1,0 -600,0"
            fill="none"
            stroke="rgb(255,255,255)"
            strokeWidth="0.2"
          />
        </svg>
        <div className="line" ref={line}></div>
      </StyledBg>
      <StyledHome ref={container} id="dismissNavbar">
        <Login ref={login} />
        <h1 ref={title}>
          Selected
          <br />
          work
        </h1>
        <div ref={addToProjectRef}>
          <Project
            direction="right"
            title="Yves Saint Laurent"
            descr="website redesign"
            text="design system - da"
            date="2020"
            image={RAL}
            imageWidth={375}
            imageHeight={400}
            link="/YvesSaintLaurentWebsite"
            name="one"
            imageMarge={4}
            lock="locked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="left"
            title="Ubisoft"
            descr="intranet"
            text="ui design - da"
            date="2021"
            image={Ubisoft}
            imageWidth={350}
            imageHeight={353}
            link="/UbisoftIntranet"
            imageMarge={-88}
            name="two"
            lock="locked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="right"
            title="Biotherm"
            descr="LIFE PLANKTON™"
            text="DIGITAL CAMPAIGN - UI DESIGN - DA"
            date="2018 - 2020"
            image={LifePlankton}
            imageWidth={390}
            imageHeight={342}
            link="/BiothermLifePlankton"
            imageMarge={78}
            name="three"
            lock="unlocked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="left"
            title="Rogé Cavaillès"
            descr="digital & brandbook"
            text="ui - social media - layout"
            date="2021"
            image={Roge}
            imageWidth={374}
            imageHeight={366}
            link="/RogeCavaillesDigitalBrandbook"
            imageMarge={13}
            name="four"
            lock="locked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="right"
            title="Beclou"
            descr="app"
            text="design system on figma"
            date="2022"
            image={Beclou}
            imageWidth={390}
            imageHeight={342}
            link="/BeclouApp"
            imageMarge={-68}
            name="five"
            lock="unlocked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="right"
            title="Prada"
            descr="website"
            text="ui design"
            date="2020"
            image={Prada}
            imageWidth={388}
            imageHeight={277}
            link="/PradaWebsite"
            imageMarge={-186}
            name="eight"
            lock="unlocked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="left"
            title="Biotherm"
            descr="skin diagnosis"
            text="ui design - da"
            date="2021"
            image={BioSkin}
            imageWidth={350}
            imageHeight={353}
            link="/BiothermSkinDiagnosis"
            imageMarge={-86}
            name="nine"
            lock="locked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="right"
            title="Yves Saint Laurent"
            descr="asian digital market"
            text="da - ui design"
            date="2019 - 2020"
            image={YSL}
            imageWidth={477}
            imageHeight={381}
            link="/YvesSaintLaurentAsianDigitalMarket"
            imageMarge={68}
            name="six"
            lock="unlocked"
            ref={addToprojectsChild}
          />
        </div>
        <div ref={addToProjectRef}>
          <Project
            direction="left"
            title="Yves Saint Laurent"
            descr="libre range"
            text="campaign digital kit"
            date="2020"
            image={Libre}
            imageWidth={355}
            imageHeight={333}
            link="/YvesSaintLaurentLibreRange"
            imageMarge={-108}
            name="seven"
            lock="unlocked"
            ref={addToprojectsChild}
          />
        </div>
        <HitMe marginTop={0} />
      </StyledHome>
    </>
  )
}

import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import {
  Home,
  Illustrations,
  Ubisoft,
  LifePlankton,
  Roge,
  Beclou,
  YslAsian,
  Libre,
  YslRal,
  Prada,
  About,
  BioSkin,
} from './Pages/index'

const App = () => {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/YvesSaintLaurentWebsite"
                render={() => <YslRal />}
              />
              <Route exact path="/UbisoftIntranet" render={() => <Ubisoft />} />
              <Route
                exact
                path="/BiothermLifePlankton"
                render={() => <LifePlankton />}
              />
              <Route
                exact
                path="/RogeCavaillesDigitalBrandbook"
                render={() => <Roge />}
              />
              <Route exact path="/BeclouApp" render={() => <Beclou />} />
              <Route
                exact
                path="/YvesSaintLaurentAsianDigitalMarket"
                render={() => <YslAsian />}
              />
              <Route
                exact
                path="/YvesSaintLaurentLibreRange"
                render={() => <Libre />}
              />
              <Route exact path="/PradaWebsite" render={() => <Prada />} />
              <Route
                exact
                path="/BiothermSkinDiagnosis"
                render={() => <BioSkin />}
              />
              <Route exact path="/About" render={() => <About />} />
              <Route
                exact
                path="/Illustrations"
                render={() => <Illustrations />}
              />
            </Switch>
          </AnimatePresence>
        )}
      />
    </Router>
  )
}

export default App

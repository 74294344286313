import styled from 'styled-components'

export const StyledText = styled.div`
  width: ${(props) => props.width}px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: ${(props) =>
      props.width === 177
        ? '100%'
        : props.width === 400 || props.width === 465
        ? '90%'
        : `${props.width}px`};
    margin: 0 auto;
  }

  p {
    font-family: 'America', sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #909090;
    line-height: 160%;
    text-align: ${(props) => props.align};
    white-space: pre-wrap;

    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
`

// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledBeclou } from './Beclou.styled'

// Images
import BeclouImg from '../../Assets/Img/Beclou/BeclouImg.webp'
import Img1 from '../../Assets/Img/Beclou/img-1.webp'
import Img2 from '../../Assets/Img/Beclou/img-2.png'
import Figma1 from '../../Assets/Img/Beclou/figma-1.png'
import Figma2 from '../../Assets/Img/Beclou/figma-2.png'
import Figma3 from '../../Assets/Img/Beclou/figma-3.png'
import Figma4 from '../../Assets/Img/Beclou/figma-4.png'
import Beclou1 from '../../Assets/Img/Beclou/beclou-01.png'
import Beclou2 from '../../Assets/Img/Beclou/beclou-02.png'
import Beclou3 from '../../Assets/Img/Beclou/beclou-03.png'
import Beclou4 from '../../Assets/Img/Beclou/beclou-04.png'
import Video1 from '../../Assets/Img/Beclou/Beclou1.mp4'
import Video2 from '../../Assets/Img/Beclou/Beclou2.mp4'
import Video3 from '../../Assets/Img/Beclou/Beclou3.mp4'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniSKINDIAG.png'
import Footer2 from '../../Assets/Img/Footer/MiniCAVAILLES.png'
import Footer3 from '../../Assets/Img/Footer/MiniBIOTHERM.png'

export const Beclou = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledBeclou ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={390}
            initialHeight={342}
            background={BeclouImg}
            title="Beclou"
            subtitle="design system - figma"
            para={
              'Personal project to practice using Figma.\nBeclou is an app that allows to book a bike in the city.\nDesign System, dark mode, components creation, prototype, testing of several plugins.'
            }
          />
          <div className="container-project">
            <div className="section-1">
              <img src={Img1} alt="" />
            </div>
            <Title title={'logo - illustrations'} />
            <div className="section-1">
              <img src={Img2} alt="" />
            </div>
            <Title title={'style guide'} />
            <div className="section-5">
              <div className="item">
                <img src={Figma4} alt="" />
              </div>
              <div className="item">
                <img src={Figma3} alt="" />
              </div>
              <div className="item">
                <img src={Figma2} alt="" />
              </div>
              <div className="item">
                <img src={Figma1} alt="" />
              </div>
            </div>
            <div className="section-2">
              <img src={Beclou4} alt="" />
            </div>
            <Title title={'icons'} />
            <div className="section-1">
              <img src={Beclou3} alt="" />
            </div>
            <Title title={'components'} />
            <div className="section-1">
              <img src={Beclou2} alt="" />
            </div>
            <div className="section-2">
              <img src={Beclou1} alt="" />
            </div>
            <Title title={'prototype'} />
            <div className="section-3">
              <div className="item">
                <video
                  src={Video1}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
              <div className="item">
                <video
                  src={Video2}
                  autoPlay
                  loop
                  muted
                  playsInline
                  type="video/mp4"
                ></video>
              </div>
            </div>
            <Title title={'dark mode'} />
            <div className="section-4">
              <video
                src={Video3}
                autoPlay
                loop
                muted
                playsInline
                type="video/mp4"
              ></video>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={true}
          img1={Footer1}
          link1="/BiothermSkinDiagnosis"
          title1="Biotherm"
          text1="skin diagnosis"
          lock2={true}
          img2={Footer2}
          link2="/RogeCavaillesDigitalBrandbook"
          title2="Rogé Cavaillès"
          text2="digital & brandbook"
          lock3={false}
          img3={Footer3}
          link3="/BiothermLifePlankton"
          title3="Biotherm"
          text3="LIFE PLANKTON™"
        />
      </StyledBeclou>
    </div>
  )
}

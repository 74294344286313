import styled from 'styled-components'

export const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  .header {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bg {
    position: absolute;
    width: ${(props) => props.initialWidth}px;
    height: ${(props) => props.initialHeight}px;
    background-image: url(${(props) => props.backgound});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-family: 'Palatino', sans-serif;
      font-size: 60px;
      font-weight: normal;
      color: #ffffff;

      @media (max-width: 1024px) {
        font-size: 50px;
      }
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }

    h2 {
      font-family: 'America', sans-serif;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 8px;
      text-transform: uppercase;
      margin-bottom: 25px;
      margin-top: 35px;

      @media (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 30px;
      }
      @media (max-width: 768px) {
        font-size: 10px;
        margin-bottom: 15px;
        margin-top: 25px;
      }
    }

    p {
      font-family: 'America', sans-serif;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 27px;
      white-space: pre-wrap;

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 24px;
      }
      @media (max-width: 768px) {
        font-size: 10px;
        line-height: 18px;
        padding: 0 15px;
      }
    }
  }
`

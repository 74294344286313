import styled from 'styled-components'

export const StyledIllustrations = styled.div`
  position: relative;
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .header {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url(${(props) => props.Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Palatino', sans-serif;
      font-size: 60px;
      font-weight: normal;
      color: #ffffff;
      margin-bottom: 50px;
    }
    a {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;

      .img {
        width: 35px;
      }

      h2 {
        font-family: 'Palatino', sans-serif;
        font-size: 40px;
        font-weight: normal;
        text-align: center;
        line-height: 120%;
        margin: 15px 0;
        color: #ffffff;
      }

      p {
        font-family: 'America', sans-serif;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 3.2px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }
  .container {
    width: calc(100% - 100px);
    padding: 90px 50px;
    max-width: 1440px;

    .container-image {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;

      .image {
        width: 30%;
        min-width: 250px;
        margin-bottom: 45px;
      }
    }
  }
`

import styled from 'styled-components'

export const StyledFooter = styled.div`
  width: 100%;
  padding: 120px 0;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 150px;
  }

  .footer {
    width: 80%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1024px) {
      width: 95%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`

export const StyledFooterItem = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    width: 250px;
  }
  @media (max-width: 1024px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
    width: 100%;
    max-width: 300px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:hover {
    .img img {
      transform: scale(1.1);
    }
  }

  .img {
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;

    img {
      transform: scale(1);
      transition: all 0.5s ease-in-out;
    }
  }

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .img {
      width: 11px;
      margin-bottom: 5px;
    }

    h3 {
      font-family: 'Palatino', sans-serif;
      font-size: 30px;
      font-weight: normal;
      color: #ffffff;
      margin-bottom: 5px;

      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }
    p {
      font-family: 'America', sans-serif;
      font-size: 8px;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 3.2px;
      text-transform: uppercase;
    }
  }
`

import styled from 'styled-components'

export const StyledYslAsian = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    position: relative;
    left: 4px;

    @media (max-width: 768px) {
      margin-top: 75px;
    }

    .emptyDiv {
      width: 300px;

      @media (max-width: 768px) {
        width: 0px;
      }
    }
  }
  .section-2 {
    width: 80%;
    margin: 100px 0 200px 0;

    @media (max-width: 768px) {
      width: 90%;
      margin: 50px 0 150px 0;
    }
  }
  .section-3 {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left {
      width: 40%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 768px) {
        width: 80%;
      }

      .small {
        position: absolute;
        z-index: 5;
        width: 50%;
        left: 0;
      }

      .big {
        width: 60%;
        position: relative;
        z-index: 3;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 50%;

      @media (max-width: 768px) {
        width: 80%;
      }

      .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 150px;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .gif {
          width: 20%;

          @media (max-width: 768px) {
            width: 40%;

            &:first-child {
              margin-bottom: 15px;
            }
            &:nth-child(2n + 1) {
              margin-right: 15px;
            }
          }
        }
      }

      .bot {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        .item {
          width: 40%;

          @media (max-width: 768px) {
            width: 70%;
          }
        }
      }
    }
  }
`

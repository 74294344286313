// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledPrada } from './Prada.styled'

// Images
import PradaImg from '../../Assets/Img/Prada/PradaImg.webp'
import Img1 from '../../Assets/Img/Prada/img-1.png'
import Img2 from '../../Assets/Img/Prada/img-2.webp'
import Img3 from '../../Assets/Img/Prada/img-3.png'
import Img4 from '../../Assets/Img/Prada/img-4.png'
import Img5 from '../../Assets/Img/Prada/img-5.png'
import Img6 from '../../Assets/Img/Prada/img-6.png'
import Img7 from '../../Assets/Img/Prada/img-7.png'
import Img8 from '../../Assets/Img/Prada/img-8.png'
import Img9 from '../../Assets/Img/Prada/img-9.png'
import Img10 from '../../Assets/Img/Prada/img-10.png'
import Img11 from '../../Assets/Img/Prada/img-11.png'
import Video1 from '../../Assets/Img/Prada/VIDEO.mp4'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniLIBRE.png'
import Footer2 from '../../Assets/Img/Footer/MiniASIANMARKET.png'
import Footer3 from '../../Assets/Img/Footer/MiniSKINDIAG.png'

export const Prada = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledPrada ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={388}
            initialHeight={277}
            background={PradaImg}
            title="Prada Beauty"
            subtitle="website"
            para={
              'We worked on a new visual identity PRADATOPIA\nfor Prada Beauty e-commerce launch, using this structure.'
            }
          />
          <div className="container-project">
            <div className="section-1">
              <video
                src={Video1}
                autoPlay
                loop
                muted
                playsInline
                type="video/mp4"
              ></video>
            </div>
            <Title title={'homepage'} />
            <div className="section-2">
              <img src={Img1} alt="" />
            </div>
            <div className="section-3">
              <div className="left">
                <img src={Img2} alt="" />
              </div>
              <div className="right">
                <img src={Img3} alt="" />
              </div>
            </div>
            <Title title={'menu'} />
            <div className="section-4">
              <img src={Img4} alt="" />
            </div>
            <Title title={'product page mobile'} />
            <div className="section-5">
              <div className="side">
                <div className="top">
                  <img src={Img5} alt="" />
                </div>
                <div className="bot">
                  <img src={Img6} alt="" />
                </div>
              </div>
              <div className="center">
                <img src={Img7} alt="" />
              </div>
              <div className="side">
                <div className="top">
                  <img src={Img8} alt="" />
                </div>
                <div className="bot">
                  <img src={Img9} alt="" />
                </div>
              </div>
            </div>
            <Title title={'e-mag'} />
            <div className="section-6">
              <div className="item">
                <img src={Img10} alt="" />
              </div>
              <div className="item">
                <img src={Img11} alt="" />
              </div>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/YvesSaintLaurentLibreRange"
          title1="Yves Saint Laurent"
          text1="campaign digital kit"
          lock2={false}
          img2={Footer2}
          link2="/YvesSaintLaurentAsianDigitalMarket"
          title2="Yves Saint Laurent"
          text2="asian market"
          lock3={true}
          img3={Footer3}
          link3="/BiothermSkinDiagnosis"
          title3="Biotherm"
          text3="skin diagnosis"
        />
      </StyledPrada>
    </div>
  )
}

// Dependencies
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Brand = () => {
  return (
    <StyledBrand>
      <Link to="/" className="navbar-brand">
        alicejestin.com
      </Link>
    </StyledBrand>
  )
}
export default Brand

// Styles
const StyledBrand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    font-family: 'America', sans-serif;
    font-weight: normal;
    font-size: 8px;
    letter-spacing: 3.2px;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
  }
`

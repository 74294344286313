import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { StyledFooterItem } from './Footer.styled'
import Login from '../Login/Login'
import AuthService from '../../Hooks/auth'

import cadenas from '../../Assets/Img/cadenas.svg'

export const FooterItem = (props) => {
  const login = useRef()
  const [showModeratorBoard, setShowModeratorBoard] = useState()
  useEffect(async () => {
    const user = await AuthService.getCurrentUser()
    if (user) {
      setShowModeratorBoard(true)
    } else {
      setShowModeratorBoard(false)
    }
  }, [])

  const openModal = () => {
    login.current.showModal()
  }
  return (
    <StyledFooterItem>
      <div className="item">
        {showModeratorBoard || !props.lock ? (
          <Link to={props.link}>
            <div className="img">
              <img src={props.img} alt="" />
            </div>
            <div className="text">
              <h3>{props.title}</h3>
              <p>{props.text}</p>
            </div>
          </Link>
        ) : (
          <>
            <Login ref={login} />
            <div className="lock" onClick={openModal}>
              <div className="img">
                <img src={props.img} alt="" />
              </div>
              <div className="text">
                <div className="img">
                  <img src={cadenas} alt="" />
                </div>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </StyledFooterItem>
  )
}

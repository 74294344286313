// Dependencies
import React from 'react'

// Styles
import { StyledHitMe } from './HitMe.styled'

export const HitMe = (props) => {
  return (
    <StyledHitMe marginTop={props.marginTop}>
      <h3>You have a project ?</h3>
      <a href="mailto:jestin.alice@gmail.com">
        Hit me up <span>!</span>
      </a>
    </StyledHitMe>
  )
}

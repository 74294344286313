import styled from 'styled-components'

export const StyledAbout = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .header {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        display: none;
      }

      h1 {
        position: relative;
        z-index: 10;
        font-family: 'Palatino', sans-serif;
        font-size: 75px;
        font-weight: normal;
        color: #ffffff;

        &:first-child {
          margin-right: -50px;
        }
        &:last-child {
          margin-left: -50px;
        }
      }
      .img {
        position: relative;
        z-index: 2;
        width: 420px;
        filter: url(#profilFilter);

        @media (max-width: 1024px) {
          filter: none;
        }
      }
    }
    .bot {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 500px;
      margin-top: 50px;

      @media (max-width: 768px) {
        display: none;
      }

      a {
        font-family: 'America', sans-serif;
        font-size: 8px;
        font-weight: normal;
        margin: 0 1rem;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        opacity: 0.3;
        letter-spacing: 3.2px;
        transition: all 0.5s ease-in-out;
        padding: 10px;

        &:hover {
          opacity: 1;
        }
      }
      p {
        font-family: 'America', sans-serif;
        font-size: 8px;
        font-weight: normal;
        color: #ffffff;
        opacity: 0.3;
      }
    }
    .mob {
      position: relative;
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 80%;
      max-width: 450px;

      @media (max-width: 768px) {
        display: flex;
      }

      .text {
        position: absolute;
        z-index: 99;
        bottom: 0;
        width: calc(100% - 10px);
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        h1 {
          position: relative;
          z-index: 10;
          font-family: 'Palatino', sans-serif;
          font-size: 65px;
          font-weight: normal;
          color: #ffffff;

          @media (max-width: 500px) {
            font-size: 45px;
          }
        }
      }

      .img {
        position: relative;
        z-index: 2;
        width: 100%;
        filter: url(#profilFilterMob);
        @media (max-width: 1024px) {
          filter: none;
        }
      }
    }
  }
  .section-1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    padding: 100px 0;

    @media (max-width: 1430px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;

      @media (max-width: 1024px) {
        width: 45%;
      }
      @media (max-width: 768px) {
        width: 80%;
        align-items: flex-start;
        margin-bottom: 50px;
      }

      h2 {
        width: 100%;
        font-family: 'Palatino', sans-serif;
        font-size: 60px;
        font-weight: normal;
        color: #ffffff;
        line-height: 130%;
        text-align: right;

        @media (max-width: 1024px) {
          font-size: 50px;
        }
        @media (max-width: 768px) {
          text-align: left;
          font-size: 40px;
        }
      }
    }
    .right {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 1024px) {
        width: 45%;
      }
      @media (max-width: 768px) {
        width: 80%;
      }

      p {
        font-family: 'America', sans-serif;
        font-size: 12px;
        font-weight: normal;
        color: #909090;
        line-height: 130%;
        margin-bottom: 50px;
        max-width: 360px;

        @media (max-width: 1024px) {
          max-width: 300px;
        }
        @media (max-width: 768px) {
          max-width: 400px;
        }
      }
      a {
        font-family: 'Palatino', sans-serif;
        font-size: 28px;
        font-weight: normal;
        color: #ffffff;
        line-height: 120%;
        text-decoration: none;
        opacity: 0.5;
        transition: all 0.5s ease-in-out;

        @media (max-width: 768px) {
          font-size: 20px;
        }

        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
  .section-2 {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 200px;

    @media (max-width: 768px) {
      margin-bottom: 150px;
    }

    .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .text {
      max-width: 730px;
      margin-top: 100px;

      h3 {
        font-family: 'Palatino', sans-serif;
        font-size: 30px;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
        line-height: 130%;

        @media (max-width: 768px) {
          font-size: 20px;
          padding: 0 15px;
        }
      }
    }
  }
  .section-3 {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .item {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 768px) {
        width: 100%;

        &:last-child {
          margin-top: 50px;
        }
      }

      .bloc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;

        a {
          font-family: 'America', sans-serif;
          font-size: 12px;
          font-weight: normal;
          color: #909090;
          line-height: 130%;
          text-decoration: none;
          transition: all 0.5s ease-in-out;
          text-transform: uppercase;

          &:hover {
            color: #ffffff;
          }
        }

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .section-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 200px;
    margin-bottom: 200px;

    h3 {
      font-family: 'Palatino', sans-serif;
      font-size: 60px;
      font-weight: normal;
      color: #ffffff;
      line-height: 120%;
    }
    a {
      font-family: 'Palatino', sans-serif;
      font-size: 60px;
      font-style: italic;
      font-weight: normal;
      color: #ffffff;
      line-height: 120%;
      display: block;
      position: relative;
      z-index: 2;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: #ffffff;
        z-index: -1;
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
        opacity: 1;
        transform: translate3d(-110%, 0, 0);
      }

      &:hover::after,
      &:focus::after {
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }
`
export const Brand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .img {
    width: ${(props) => props.width}px;
    margin-right: 20px;

    @media (max-width: 768px) {
      width: ${(props) => props.width * 0.75}px;
    }
  }

  h2 {
    font-family: 'Palatino', sans-serif;
    font-size: 30px;
    font-weight: normal;
    color: #ffffff;
    margin-right: ${(props) => props.margin}px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`
export const AboutTitle = styled.p`
  font-family: 'America', sans-serif;
  font-size: 8px;
  font-weight: normal;
  color: #909090;
  margin-bottom: 100px;
  text-transform: uppercase;
  letter-spacing: 3.2px;

  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
`
export const CvTitle = styled.h2`
  font-family: 'Palatino', sans-serif;
  font-size: 60px;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 30px;
  line-height: 130%;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`
export const CvSubTitle = styled.p`
  font-family: 'America', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #909090;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: 5px;
`
export const CvText = styled.p`
  font-family: 'America', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #909090;
  line-height: 160%;
  margin-bottom: 20px;
`

import styled from 'styled-components'

export const StyledYslRal = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .left {
      width: 75%;

      @media (max-width: 768px) {
        width: 95%;
        margin-bottom: 50px;
      }
    }
    .right {
      width: 20%;
      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }
  .section-2 {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .left {
      position: relative;
      width: 56%;
      z-index: 2;
      left: 0;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
    .right {
      position: absolute;
      z-index: 3;
      right: 0;
      top: 0;
      width: 54%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        position: relative;
        width: 85%;
        margin-top: -60px;
      }

      .top {
        position: relative;
        z-index: 4;
        width: 50%;
        top: 50px;
        margin-bottom: -50px;
      }
      .bot {
        position: relative;
        z-index: 3;
        width: 100%;
      }
    }
  }
  .section-3 {
    position: relative;
    margin-top: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      margin-top: 150px;
    }

    .bg {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
    }

    .center {
      position: relative;
      width: 50%;
      z-index: 5;

      @media (max-width: 768px) {
        width: 85%;
      }
    }
  }
  .section-4 {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 95%;
      justify-content: center;
    }

    .left {
      width: 60%;
      position: relative;
      left: 0;

      @media (max-width: 768px) {
        width: 90%;
      }
    }
    .right {
      width: 60%;
      position: absolute;
      right: 0;

      @media (max-width: 768px) {
        width: 90%;
      }
    }
    .item {
      width: 100%;
      margin-bottom: 50px;
      position: relative;
      top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .it-1 {
      z-index: 50;
    }
    .it-2 {
      z-index: 40;
    }
    .it-3 {
      z-index: 30;
    }
    .it-4 {
      z-index: 20;
    }
    .it-5 {
      z-index: 10;
    }
  }
`

// Dependencies
import React, { useState } from 'react'
import Navbar from './Navbar'

const Navigation = () => {
  // States
  const [navbarOpen, setNavbarOpen] = useState(false)

  // Functions
  const handleNavbar = () => {
    let el = document.getElementById('dismissNavbar')
    navbarOpen ? (el.style.opacity = '1') : (el.style.opacity = '0')
    setNavbarOpen(!navbarOpen)
  }

  // Rendering
  return (
    <>
      <Navbar navbarState={navbarOpen} handleNavbar={handleNavbar} />
    </>
  )
}
export default Navigation

import styled from 'styled-components'

export const StyledHitMe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(props) => props.marginTop}px;
  padding-bottom: 200px;

  @media (max-width: 767px) {
    margin-top: ${(props) => (props.marginTop === 0 ? 70 : props.marginTop)}px;
    padding-bottom: ${(props) => (props.marginTop === 0 ? 70 : 0)}px;
  }

  h3 {
    font-family: 'Palatino', sans-serif;
    font-size: 60px;
    font-weight: normal;
    color: #ffffff;
    line-height: 120%;

    @media (max-width: 767px) {
      font-size: 40px;
    }
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
  a {
    font-family: 'Palatino', sans-serif;
    font-size: 60px;
    font-style: italic;
    font-weight: normal;
    color: #ffffff;
    line-height: 120%;
    display: block;
    position: relative;
    z-index: 2;
    overflow: hidden;

    @media (max-width: 767px) {
      font-size: 40px;
    }
    @media (max-width: 500px) {
      font-size: 30px;
    }

    span {
      position: relative;
      display: inline-block;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      z-index: -1;
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
      transform: translate3d(0, 0, 0);
    }

    &:hover::after,
    &:focus::after {
      animation-duration: 0.5s;
      animation-name: anim;
    }

    &:hover,
    &:focus {
      span {
        animation-duration: 0.5s;
        animation-name: bounce;
      }
    }
  }
  @keyframes anim {
    from {
      opacity: 1;
      transform: translate3d(-110%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes bounce {
    from {
      transform: rotate(10deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(5deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`

// Dependencies
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledHeader } from './Header.styled'

// Variables
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

export const Header = (props) => {
  // Hooks
  const { height, width } = useWindowDimensions()

  // States
  const [posx, setPosx] = useState()
  const [posy, setPosy] = useState()

  // Functions
  useEffect(() => {
    setPosx(width / 2 - props.initialWidth / 2)
    setPosy(height / 2 - props.initialHeight)
  }, [])

  // Rendering
  return (
    <StyledHeader
      initialWidth={props.initialWidth}
      initialHeight={props.initialHeight}
      backgound={props.background}
    >
      <motion.div
        className="header"
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div
          className="bg"
          initial={{
            width: props.initialWidth + 'px',
            height: props.initialHeight + 'px',
            top: posy + 'px',
            left: posx + 'px',
          }}
          animate={{
            y: 0,
            top: 0,
            height: height,
            opacity: 0.7,
            width: '100%',
            transition: { delay: 0.2, ...transition },
          }}
        ></motion.div>
        <motion.div
          className="text"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { delay: 0.8, ...transition },
          }}
        >
          <h1>{props.title}</h1>
          <h2>{props.subtitle}</h2>
          <p>{props.para}</p>
        </motion.div>
      </motion.div>
    </StyledHeader>
  )
}

import styled from 'styled-components'

export const StyledBeclou = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 80%;

    @media (max-width: 768px) {
      width: 95%;
    }
  }
  .section-2 {
    width: 80%;
    margin-top: 100px;

    @media (max-width: 768px) {
      width: 95%;
      margin-top: 50px;
    }
  }
  .section-3 {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
      width: 95%;
    }

    .item {
      width: 100%;

      &:last-child {
        margin-top: 75px;

        @media (max-width: 768px) {
          margin-top: 30px;
        }
      }
    }
  }
  .section-4 {
    width: 100%;
  }
  .section-5 {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .item {
      width: 24%;

      @media (max-width: 768px) {
        width: 60%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      @media (max-width: 500px) {
        width: 75%;
      }
    }
  }
`

// Dependencies
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

const CollapseMenu = (props) => {
  // Variables
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })

  // Rendering
  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0, 0, 1],
              output: [0, -20, 0, -50],
            })
            .interpolate((openValue) => `translate3d(0, ${openValue}px, 0`),
        }}
      >
        <NavLinks>
          <li className="socialCollapse">
            <a
              href="https://www.linkedin.com/in/alice-jestin-9b261112b/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.handleNavbar}
            >
              _likdn
            </a>
            <a
              href="https://www.behance.net/jestinalicaf0f"
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.handleNavbar}
            >
              _be
            </a>
            <a
              href="https://www.instagram.com/alicejstnart/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.handleNavbar}
            >
              _inst
            </a>
            <a
              href="https://dribbble.com/AliceJstn"
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.handleNavbar}
            >
              _dr
            </a>
          </li>
          <li>
            <Link to="/About" onClick={props.handleNavbar}>
              About
            </Link>
          </li>
          <li>
            <Link to="/Illustrations" onClick={props.handleNavbar}>
              Illustrations
            </Link>
          </li>
          <li>
            <Link to="/" onClick={props.handleNavbar}>
              Projects
            </Link>
          </li>
        </NavLinks>
      </CollapseWrapper>
    )
  }
  return null
}
export default CollapseMenu

// Styles
const CollapseWrapper = styled(animated.div)`
  background: transparent;
  position: fixed;
  top: 160px;
  height: calc(100% - 160px);
  left: 0;
  right: 0;
  z-index: 20;
  pointer-events: all;
  @media (max-width: 750px) {
    top: 140px !important;
  }
  @media (max-width: 560px) {
    top: 100px !important;
  }

  .socialCollapse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 560px) {
      margin-bottom: 30px;
    }

    a {
      font-family: 'America', sans-serif;
      font-size: 15px;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      text-decoration: none;
      opacity: 0.5;
      transition: all 0.5s ease-in-out;
      padding: 10px 20px;

      @media (max-width: 410px) {
        padding: 10px 0;
        width: 50%;
        text-align: center;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
`

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 1rem;
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 560px) {
    height: auto;
  }
  & li {
    transition: all 300ms linear 0s;
  }
  & a {
    line-height: 2;
    color: #ffffff;
    letter-spacing: 3.33px;
    font-family: 'Palatino', sans-serif;
    font-weight: normal;
    font-size: 35px;
    text-decoration: none;
    cursor: pointer;
    @media (max-width: 560px) {
      font-size: 25px;
    }
    &:hover {
      color: #ffffff;
      border-bottom: 1px solid #ffffff;

      @media (max-width: 1050px) {
        border-bottom: none;
      }
    }
  }
`

import styled from 'styled-components'

export const StyledPrada = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    width: 75%;
    margin-top: 200px;

    @media (max-width: 768px) {
      width: 90%;
      margin-top: 150px;
    }
  }
  .section-2 {
    width: 70%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .section-3 {
    position: relative;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 100px;

    @media (max-width: 768px) {
      width: 90%;
    }

    .left {
      position: relative;
      z-index: 2;
      width: 82%;

      @media (max-width: 768px) {
        width: 75%;
      }
    }
    .right {
      position: absolute;
      right: 0;
      z-index: 3;
      margin-top: 100px;
      width: 20%;

      @media (max-width: 768px) {
        width: 30%;
      }
    }
  }
  .section-4 {
    width: 70%;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .section-5 {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .side {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        width: 70%;
      }

      .top {
        margin-bottom: 100px;

        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
      .top,
      .bot {
        width: 100%;
      }
      .bot {
        @media (max-width: 768px) {
          width: 87%;
        }
      }
    }
    .center {
      width: 25%;

      @media (max-width: 768px) {
        width: 60%;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
  .section-6 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 70%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .item {
      width: 40%;

      @media (max-width: 768px) {
        width: 100%;

        &:last-child {
          width: 65%;
        }
      }
    }
  }
`

import styled from 'styled-components'

export const StyledBioSkin = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }

  .section-1 {
    margin-top: 150px;
    width: 90%;

    @media (max-width: 768px) {
      width: 95%;
      margin-top: 75px;
    }
  }
  .section-2 {
    width: 55%;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 60%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .item {
      width: 28%;

      @media (max-width: 768px) {
        width: 90%;

        &:nth-child(2n) {
          margin: 30px 0;
          width: 100%;
        }
      }
    }
  }
`

import styled from 'styled-components'

export const StyledHome = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  display: ${(props) => props.display};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > div {
    width: 100%;
  }

  h1 {
    position: relative;
    padding-top: calc(50vh - 75px);
    font-family: 'Palatino', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 75px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 140px;

    @media (max-width: 767px) {
      padding-top: calc(50vh - 60px);
      font-size: 60px;
    }
    @media (max-width: 500px) {
      padding-top: calc(50vh - 50px);
      font-size: 50px;
    }
  }
`
export const StyledBg = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0f0f0f;

  svg {
    position: fixed;
    width: 600px;
    height: 600px;
    left: calc(50% - 300px);
    top: calc(50% - 300px);
    //border-radius: 50%;
    //border: 1px solid rgba(255, 255, 255, 0.45);
    opacity: 0.35;
    transform: rotate(-90deg);

    @media (max-width: 767px) {
      width: 400px;
      height: 400px;
      left: calc(50% - 200px);
      top: calc(50% - 200px);
    }
    @media (max-width: 500px) {
      width: 300px;
      height: 300px;
      left: calc(50% - 150px);
      top: calc(50% - 150px);
    }
  }

  .line {
    position: relative;
    width: 0.8px;
    height: 100%;
    left: calc(50% - 0.4px);
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.15;
  }
  #counter {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'America', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
  }
`

// Dependencies
import React from 'react'

// Styles
import { StyledText } from './Text.styled'

export const Text = (props) => {
  return (
    <StyledText width={props.width} align={props.align}>
      <p>{props.text}</p>
    </StyledText>
  )
}

// Dependencies
import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

const Social = () => {
  // Functions
  const barAnimation = useSpring({
    from: { left: '-110px', opacity: 0 },
    to: { left: '-80px', opacity: 1 },
    delay: sessionStorage.getItem('loader') ? 800 : 7300,
  })

  // Rendering
  return (
    <StyledSocial style={barAnimation}>
      <a
        href="https://www.linkedin.com/in/alice-jestin-9b261112b/"
        target="_blank"
        rel="noopener noreferrer"
      >
        _lkdn
      </a>
      <p>-</p>
      <a
        href="https://www.behance.net/jestinalicaf0f"
        target="_blank"
        rel="noopener noreferrer"
      >
        _be
      </a>
      <p>-</p>
      <a
        href="https://www.instagram.com/alicejstnart/"
        target="_blank"
        rel="noopener noreferrer"
      >
        _inst
      </a>
      <p>-</p>
      <a
        href="https://dribbble.com/AliceJstn"
        target="_blank"
        rel="noopener noreferrer"
      >
        _db
      </a>
    </StyledSocial>
  )
}
export default Social

// Styles
const StyledSocial = styled(animated.div)`
  position: fixed;
  z-index: 10;
  top: calc(50vh - 15px);
  left: -80px;
  transform: rotate(-90deg);
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1050px) {
    display: none;
  }

  p {
    font-family: 'America', sans-serif;
    font-size: 8px;
    font-weight: normal;
    color: #ffffff;
    opacity: 0.3;
  }

  a {
    font-family: 'America', sans-serif;
    font-size: 8px;
    font-weight: normal;
    color: #ffffff;
    opacity: 0.3;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    padding: 10px 5px;

    &:hover {
      opacity: 1;
    }
  }
`

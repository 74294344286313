import React from 'react'
import ReactDOM from 'react-dom'
import GlobalFonts from './Styles/GlobalFonts'
import ResetStyles from './Styles/ResetStyles'
import GlobalStyles from './Styles/GlobalStyles'
import App from './App'
//import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <GlobalFonts />
    <ResetStyles />
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()

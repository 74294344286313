// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import { Text } from '../../Components/Text/Text'
import Navigation from '../../Components/Navbar/Navigation'
import { Footer } from '../../Components/Footer/Footer'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledYslAsian } from './YslAsian.styled'

// Images
import YslAsianImg from '../../Assets/Img/YslAsian/YslAsianImg.webp'
import Img1 from '../../Assets/Img/YslAsian/img-1.png'
import Img2 from '../../Assets/Img/YslAsian/img-2.webp'
import Img3 from '../../Assets/Img/YslAsian/img-3.webp'
import Img4 from '../../Assets/Img/YslAsian/img-4.webp'
import Img5 from '../../Assets/Img/YslAsian/img-5.png'
import Gif1 from '../../Assets/Img/YslAsian/gif-1.gif'
import Gif2 from '../../Assets/Img/YslAsian/gif-2.gif'
import Gif3 from '../../Assets/Img/YslAsian/gif-3.gif'
import Gif4 from '../../Assets/Img/YslAsian/gif-4.gif'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniPRADA.png'
import Footer2 from '../../Assets/Img/Footer/MiniUBISOFT.png'
import Footer3 from '../../Assets/Img/Footer/MiniYSL.png'

export const YslAsian = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Function
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledYslAsian ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={477}
            initialHeight={381}
            background={YslAsianImg}
            title="Yves Saint Laurent"
            subtitle="asian market"
            para={
              'During my experience @PublicisLuxe,\nI had the opportunity to work on many immersive\npages to present products, events / campaigns,\non social media like Wechat, Weibo, or Tmall.\nThis included layout, illustrations, animations, gif or graphic\nprinciples related to each topic.'
            }
          />
          <div className="container-project">
            <div className="section-1">
              <div className="emptyDiv"></div>
              <Text
                width={300}
                align={'left'}
                text={
                  'Example on Chinese New Year event,\nthe launch of the new Libre eau de Parfum,\nCushion Collector Edition,\n& Milk Tea Lipstick campaign.'
                }
              />
            </div>
            <div className="section-2">
              <img src={Img1} alt="" />
            </div>
            <div className="section-3">
              <div className="left">
                <div className="small">
                  <img src={Img5} alt="" />
                </div>
                <div className="big">
                  <img src={Img2} alt="" />
                </div>
              </div>
              <div className="right">
                <div className="top">
                  <div className="gif">
                    <img src={Gif1} alt="" />
                  </div>
                  <div className="gif">
                    <img src={Gif2} alt="" />
                  </div>
                  <div className="gif">
                    <img src={Gif3} alt="" />
                  </div>
                  <div className="gif">
                    <img src={Gif4} alt="" />
                  </div>
                </div>
                <div className="bot">
                  <div className="item">
                    <img src={Img3} alt="" />
                  </div>
                  <div className="item">
                    <img src={Img4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={false}
          img1={Footer1}
          link1="/PradaWebsite"
          title1="Prada"
          text1="website"
          lock2={true}
          img2={Footer2}
          link2="/UbisoftIntranet"
          title2="Ubisoft"
          text2="intranet"
          lock3={true}
          img3={Footer3}
          link3="/YvesSaintLaurentWebsite"
          title3="Yves Saint Laurent"
          text3="website"
        />
      </StyledYslAsian>
    </div>
  )
}

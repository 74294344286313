// Dependencies
import React, { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Title } from '../../Components/Title/Title'
import { Header } from '../../Components/Header/Header'
import { Backgroud } from '../../Components/Background/Backgroud'
import { Footer } from '../../Components/Footer/Footer'
import Navigation from '../../Components/Navbar/Navigation'
import { useWindowDimensions } from '../../Hooks/useWindowDimension'

// Styles
import { StyledYslRal } from './YslRal.styled'

// Images
import YslRalImg from '../../Assets/Img/YslRal/YslRalImg.webp'
import Img1 from '../../Assets/Img/YslRal/img-1.webp'
import Img2 from '../../Assets/Img/YslRal/img-2.png'
import Img3 from '../../Assets/Img/YslRal/img-3.png'
import Img4 from '../../Assets/Img/YslRal/img-4.png'
import Img5 from '../../Assets/Img/YslRal/img-5.png'
import Img6 from '../../Assets/Img/YslRal/img-6.png'
import Img7 from '../../Assets/Img/YslRal/img-7.webp'
import Img8 from '../../Assets/Img/YslRal/img-8.webp'
import Img9 from '../../Assets/Img/YslRal/img-9.png'
import Img10 from '../../Assets/Img/YslRal/img-10.png'
import Img11 from '../../Assets/Img/YslRal/img-11.png'
import Img12 from '../../Assets/Img/YslRal/img-12.webp'
import Img13 from '../../Assets/Img/YslRal/img-13.png'
import Img14 from '../../Assets/Img/YslRal/img-14.png'
import Img15 from '../../Assets/Img/YslRal/img-15.png'
import Img16 from '../../Assets/Img/YslRal/img-16.png'
import Img17 from '../../Assets/Img/YslRal/img-17.png'
import Img18 from '../../Assets/Img/YslRal/img-18.png'
import Img19 from '../../Assets/Img/YslRal/img-19.png'
import Img20 from '../../Assets/Img/YslRal/img-20.png'
import Img21 from '../../Assets/Img/YslRal/img-21.png'
import Img22 from '../../Assets/Img/YslRal/img-22.png'

// Footer Images
import Footer1 from '../../Assets/Img/Footer/MiniSKINDIAG.png'
import Footer2 from '../../Assets/Img/Footer/MiniLIBRE.png'
import Footer3 from '../../Assets/Img/Footer/MiniPRADA.png'

export const YslRal = () => {
  // Hooks
  const size = useWindowDimensions()

  // Refs
  const page = useRef()
  const scrollContainer = useRef()

  // Location
  const { pathname } = useLocation()

  // Variables
  const skewConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  }
  let throttleTimer

  // Functions
  const throttle = (callback, time = 1000) => {
    if (throttleTimer) return
    throttleTimer = true
    setTimeout(() => {
      callback()
      throttleTimer = false
    }, time)
  }
  useEffect(() => {
    const onScroll = () => {
      document.body.style.height = `${
        scrollContainer.current.getBoundingClientRect().height
      }px`
    }
    window.removeEventListener('scroll', onScroll)
    window.addEventListener(
      'scroll',
      () => {
        throttle(onScroll)
      },
      { passive: true }
    )
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  useEffect(() => {
    document.body.style.height = `${
      scrollContainer.current.getBoundingClientRect().height
    }px`
  }, [size.height])
  useEffect(() => {
    requestAnimationFrame(() => skewScrolling())
  }, [])
  const skewScrolling = () => {
    skewConfigs.current = window.scrollY
    skewConfigs.previous +=
      (skewConfigs.current - skewConfigs.previous) * skewConfigs.ease
    skewConfigs.rounded = Math.round(skewConfigs.previous * 100) / 100
    scrollContainer.current.style.transform = `translate3d(0, -${skewConfigs.rounded}px, 0)`
    requestAnimationFrame(() => skewScrolling())
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Rendering
  return (
    <div className="Page" ref={page}>
      <Backgroud />
      <Navigation />
      <StyledYslRal ref={scrollContainer} id="dismissNavbar">
        <motion.div initial="initial" animate="animate" exit="exit">
          <Header
            initialWidth={375}
            initialHeight={400}
            background={YslRalImg}
            title="Yves Saint Laurent"
            subtitle="website redesign"
            para={
              "L'Oréal launched a Design System,\na common design structure used for all their websites.\nI had to make sure to maintain the consistency\nof the YSL visual identity using this structure."
            }
          />
          <div className="container-project">
            <Title title={'homepage'} />
            <div className="section-1">
              <div className="left">
                <img src={Img1} alt="" />
              </div>
              <div className="right">
                <img src={Img2} alt="" />
              </div>
            </div>
            <Title title={'product page\nlipstick'} />
            <div className="section-2">
              <div className="left">
                <img src={Img3} alt="" />
              </div>
              <div className="right">
                <div className="top">
                  <img src={Img4} alt="" />
                </div>
                <div className="bot">
                  <img src={Img5} alt="" />
                </div>
              </div>
            </div>
            <div className="section-3">
              <div className="bg">
                <img src={Img7} alt="" />
              </div>
              <div className="center">
                <img src={Img6} alt="" />
              </div>
            </div>
            <Title title={'product page\nskincare'} />
            <div className="section-2">
              <div className="left">
                <img src={Img8} alt="" />
              </div>
              <div className="right">
                <div className="top">
                  <img src={Img9} alt="" />
                </div>
                <div className="bot">
                  <img src={Img10} alt="" />
                </div>
              </div>
            </div>
            <div className="section-3">
              <div className="bg">
                <img src={Img12} alt="" />
              </div>
              <div className="center">
                <img src={Img11} alt="" />
              </div>
            </div>
            <Title title={'beauty profile'} />
            <div className="section-4">
              <div className="left">
                <div className="item it-1">
                  <img src={Img13} alt="" />
                </div>
                <div className="item it-3">
                  <img src={Img15} alt="" />
                </div>
                <div className="item it-5">
                  <img src={Img17} alt="" />
                </div>
              </div>
              <div className="right">
                <div className="item it-2">
                  <img src={Img14} alt="" />
                </div>
                <div className="item it-4">
                  <img src={Img16} alt="" />
                </div>
              </div>
            </div>
            <Title title={'find my shade'} />
            <div className="section-4">
              <div className="left">
                <div className="item it-1">
                  <img src={Img18} alt="" />
                </div>
                <div className="item it-3">
                  <img src={Img20} alt="" />
                </div>
                <div className="item it-5">
                  <img src={Img22} alt="" />
                </div>
              </div>
              <div className="right">
                <div className="item it-2">
                  <img src={Img19} alt="" />
                </div>
                <div className="item it-4">
                  <img src={Img21} alt="" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <Footer
          lock1={true}
          img1={Footer1}
          link1="/BiothermSkinDiagnosis"
          title1="Biotherm"
          text1="skin diagnosis"
          lock2={false}
          img2={Footer2}
          link2="/YvesSaintLaurentLibreRange"
          title2="Yves Saint Laurent"
          text2="campaign digital kit"
          lock3={false}
          img3={Footer3}
          link3="/PradaWebsite"
          title3="Prada"
          text3="website"
        />
      </StyledYslRal>
    </div>
  )
}
